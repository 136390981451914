import { useTranslation } from 'react-i18next'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { CheckBox } from '@mui/icons-material'

import { RewardShopModels, RootState } from '@/state'
import { useBreakpoints } from '@/hooks'
import { ProductFormAddressComponent } from './product-form-address.component'
import { useSelector } from 'react-redux'

type Props = {
  productForm: RewardShopModels.ProductFormType
  editLocation: boolean
  product: RewardShopModels.ProductType
  actionButtons: React.ReactNode
  handleEditLocation: () => void
  handleOnChange: (field: string, newValue: string | number | boolean) => void
  handleOnChangeMultiple: (
    fields: Partial<RewardShopModels.ProductFormType>,
  ) => void
  getDisableMessage: () => React.ReactNode
}

export function ProductFormStep2Component({
  productForm,
  editLocation,
  product,
  actionButtons,
  handleEditLocation,
  handleOnChange,
  handleOnChangeMultiple,
  getDisableMessage,
}: Props) {
  const { t } = useTranslation()

  const { isTablet, isMobile } = useBreakpoints()

  const { player } = useSelector(({ player }: RootState) => player)

  return (
    <>
      <Box
        minHeight={!(isTablet || isMobile) && 400}
        display="flex"
        flexDirection="column"
      >
        {!player?.shippingAddress && (
          <FormControlLabel
            control={
              <Checkbox
                name="sendToEmail"
                checked={productForm.sendToEmail}
                onChange={(e) => {
                  handleOnChangeMultiple({
                    sendToEmail: e.target.checked,
                    receives: '',
                  })
                }}
                checkedIcon={<CheckBox />}
              />
            }
            label={t('sendToMyPersonalEmail')}
            sx={{ mb: 2 }}
          />
        )}
        <ProductFormAddressComponent
          product={product}
          productForm={productForm}
          handleOnChange={handleOnChange}
        />
        {!(isTablet || isMobile) && (
          <Box
            width="100%"
            display="flex"
            gap={2}
            justifyContent="end"
            mt="auto"
          >
            {actionButtons}
          </Box>
        )}
      </Box>

      {getDisableMessage()}
    </>
  )
}
