import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RewardTypeEnum } from '@gamanza-engage/mf-enums'

import {
  ChestTypeEnum,
  RewardProcessorStatusEnum,
  RewardTriggerTypeEnum,
  UIDialogsEnum,
} from '@/enums'
import {
  GeneralModels,
  MyRewardsModels,
  MyRewardsThunks,
  RootState,
  useAppDispatch,
} from '@/state'
import { useGlobalUi } from '@/hooks'

import { removeEmptyProp } from '@/utils'

export const useMyRewards = () => {
  const dispatch = useAppDispatch()

  const { playerId, baseUrl } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { myRewardsByTrigger } = useSelector(
    ({ myRewards }: RootState) => myRewards,
  )

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { boostersEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { handleOpenDialog } = useGlobalUi()

  const handleShowDialogShop = () => {
    handleOpenDialog({ id: UIDialogsEnum.LIST_MY_BOOSTERS })
  }

  const getRewardsByTriggerAction = useCallback(() => {
    dispatch(
      MyRewardsThunks.getRewardsByTriggerThunk({
        playerId: playerId,
        limit: 100,
        page: 1,
        status: RewardProcessorStatusEnum.UNCLAIMED,
      }),
    )
  }, [dispatch]) // eslint-disable-line

  const openChestAnimation = (
    triggerType: string,
    firstGroup: MyRewardsModels.RewardsByGroupType,
  ) => {
    const formattedRewards = firstGroup.rewards.map((reward) => {
      const newReward: GeneralModels.RewardCategoryType = {
        rewardType: reward.type || reward.rewardType,
        translations: reward.translations,
        amount:
          reward.type !== RewardTypeEnum.TOKENS ? reward.amount : undefined,
        bonusId: reward.bonusOfferId,
        timeFrame: reward.timeFrame,
        boosterRate: reward.boosterRate,
        tokens:
          reward.type === RewardTypeEnum.TOKENS ? reward.amount : undefined,
      }
      return removeEmptyProp(newReward)
    })

    const dialogObject: MyRewardsModels.MyRewardsDialogType = {
      rewards: formattedRewards,
    }

    switch (triggerType) {
      case RewardTriggerTypeEnum.RANK:
      case RewardTriggerTypeEnum.LEVEL:
        dialogObject.groupId = firstGroup.groupId
        dialogObject.chestType =
          triggerType === RewardTriggerTypeEnum.RANK
            ? ChestTypeEnum.RANK
            : ChestTypeEnum.LEVEL
        break

      case RewardTriggerTypeEnum.MISSION:
        dialogObject.missionId = firstGroup.metadata?.missionStateId
        dialogObject.bundleId = firstGroup.metadata?.bundleStateId
        dialogObject.groupId = firstGroup.groupId
        dialogObject.chestType = ChestTypeEnum.MISSION
        break

      case RewardTriggerTypeEnum.EASTER_EGGS:
        dialogObject.chestType = ChestTypeEnum.MISSION // TODO: This will be changed in the future with the easter eggs implementation
        break

      default:
        return
    }

    handleOpenDialog({
      id: UIDialogsEnum.CHEST_REWARDS,
      object: dialogObject,
    })
  }

  const claimRewardsAction = (triggerType: string) => {
    const rewardsByTrigger = Object.keys(myRewardsByTrigger[triggerType]).map(
      (key) => myRewardsByTrigger[triggerType][key],
    )

    if (rewardsByTrigger.length > 0) {
      const firstGroup = rewardsByTrigger[0]
      openChestAnimation(triggerType, firstGroup)
    }
  }

  useEffect(() => {
    getRewardsByTriggerAction()
  }, []) // eslint-disable-line

  return {
    myRewardsByTrigger,
    gamificationOpt,
    boostersEnable,
    baseUrl,
    claimRewardsAction,
    handleShowDialogShop,
  }
}
