export const rewardShop = {
  all: 'All',
  backToList: 'Back to list',
  bonusOffer: 'Bonus Offer',
  buyNow: 'Buy Now',
  checkout: 'Check Out',
  city: 'City',
  continueShopping: 'Continue Shopping',
  country: 'Country',
  description: 'Description',
  disabledStock: 'There is not enough stock for this product',
  disabledSubmit: "You don't have enough tokens to buy this product",
  email: 'Email',
  inStock: 'In Stock',
  item: 'Item',
  lowStock: 'Only {{remainingItems}} left in stock now',
  noProducts: 'No Products',
  noProductsHaveBeenBought: 'You haven’t bought any products yet.',
  noProductsMessage:
    'Our shelves are empty for now, but the excitement never stops. Keep playing and return soon!',
  noPurchasesYet: 'No Purchases Yet',
  noRewardsYet: 'No Rewards Yet',
  out_of_stock: 'Out of Stock',
  pageError: 'Page Error',
  pageErrorMessage: 'Sorry there was a connection problem',
  paymentError: 'Payment Error',
  paymentMessageSuccess: 'The purchase was made successfully',
  paymentMethods: 'Payment Methods',
  paymentSuccess: 'Payment Success',
  postalCode: 'Postal Code',
  price: 'Price',
  productDetails: 'Product Details',
  purchased: 'Purchased',
  purchasedDate: 'Purchased Date',
  qty: 'QTY',
  readLess: 'Read Less',
  readMore: 'Read More',
  receives: 'Who receives the item?',
  remainingTokens: 'Remaining Tokens:',
  requiredField: 'This field is required.',
  requiredFieldMessage: 'All fields are required.',
  requiredRankMessage: `You don't have the necessary Rank to buy this product.`,
  rewardCategorybonus: 'Bonus',
  rewardCategorybooster: 'Booster',
  rewardCategoryexternal_product: 'Products',
  rewardCategoryxp: 'XP Points',
  rewards: 'Rewards',
  rewardShop: 'Reward Shop',
  rewardShopDescription:
    'Here you can redeem your tokens for attractive rewards, view your order history and activate boosters to collect tokens faster.',
  selectDelivery: 'Select delivery location',
  sendToMyPersonalEmail: 'Send to my personal email',
  shipped: 'Shipped',
  shippingInformation: 'Shipping Information',
  shopHistory: 'Shop History',
  shopTitle: 'Shop',
  street: 'Street',
  streetNumber: 'Street Number',
  third_party: '3rd Party Ordered',
}
