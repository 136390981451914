import { createSlice } from '@reduxjs/toolkit'
import { getGlobalSettingsExtraReducer, GlobalSettingsModels } from '../'

const initialState: GlobalSettingsModels.GlobalSettingsState = {
  assetsRoute: '',
  boostersEnable: false,
  gamificationEnable: false,
  gamificationReasons: [],
  missionsEnable: false,
  rewardClaimEnable: false,
}

export const globalSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getGlobalSettingsExtraReducer(builder)
  },
})

export const GlobalSettingsActions = globalSettingsSlice.actions
