import { useSelector } from 'react-redux'
import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { Grid } from '@mui/material'

import { CardItemComponent, PlayerTokensComponent } from '@/components/common'
import { RewardShopModels, RootState } from '@/state'
import { ImageDefault } from '@/constants'

import { getExpirationText } from '@/utils'

type Props = {
  handleOpenProductDetail: (productId: string) => void
  product: RewardShopModels.ProductType
  fullList?: boolean
}

export function ProductCardComponent({
  product,
  fullList,
  handleOpenProductDetail,
}: Props) {
  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const translation =
    product.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || product.translations?.[0]

  return (
    <Grid
      item
      key={product._id}
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      width="100%"
      sx={
        fullList
          ? { flex: '0 0 280px!important', maxWidth: ' 280px!important' }
          : {}
      }
    >
      <CardItemComponent
        key={product._id}
        contentChip={
          product?.reward?.type === RewardShopTypeEnum.BOOSTER
            ? getExpirationText(product?.reward?.booster).join('')
            : undefined
        }
        item={{
          id: product._id,
          title: translation?.name || product.name,
          description: translation?.description || product.description,
          image:
            product?.images?.length > 0
              ? product.images[0].smallImage
              : product?.mobileImage || ImageDefault,
          imageHover:
            product?.images?.length > 1 && product.images[1].smallImage,
          ranks: product.ranks?.map((item) => ({
            name:
              item?.translations?.find(
                (trans) =>
                  trans.language.toUpperCase() === playerLocale.toUpperCase(),
              )?.name ||
              item?.translations?.[0]?.name ||
              item?.name,
            id: item._id,
          })),
          tags: product.tags,
        }}
        cardContentComponent={
          <PlayerTokensComponent
            tokensAmount={product.reward.price.virtualCurrencyPrice}
          />
        }
        onClick={() => handleOpenProductDetail(product._id)}
      />
    </Grid>
  )
}
