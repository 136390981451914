import { RewardStatusEnum, RewardTypeEnum } from '@gamanza-engage/mf-enums'
import {
  GuaranteesEnum,
  NotificationTypeEnum,
  RewardProcessorStatusEnum,
  RewardTriggerTypeEnum,
  SnackbarSeverityEnum,
  SocketEventsEnum,
  TrackingTypeEnum,
  UIDialogsEnum,
} from '@/enums'
import i18n from '@/i18n'

import { getSocketInstance } from '@/utils'
import { GuaranteeType, SocketErrorResponse } from './models'
import {
  PlayerActions,
  store,
  PlayerModels,
  globalUiActions,
  MyBoosterModels,
  MyBoosterActions,
  MissionHistoryModels,
  MissionHistoryActions,
  OnSiteActions,
  OnSiteModels,
  MyRewardsActions,
  MyRewardsThunks,
} from './'

export const gamificationSubscription = () => {
  const socket = getSocketInstance()

  const state = store.getState()

  const { playerId } = state.initConfig

  const ackMessage = (guarantee: GuaranteeType, messageId: string) => {
    if (guarantee.guaranteeType === GuaranteesEnum.RELIABLE_RECEIPT)
      socket.emit(SocketEventsEnum.ACK, messageId)

    if (guarantee.guaranteeType === GuaranteesEnum.RELIABLE_DELIVERY)
      socket.emit(SocketEventsEnum.ACK, {
        messageId,
      })
  }

  socket.on(SocketEventsEnum.ERROR, (response: SocketErrorResponse) => {
    store.dispatch(
      globalUiActions.setSnackbar({
        open: true,
        message: response.message,
        severity: SnackbarSeverityEnum.ERROR,
      }),
    )
  })

  socket.on(
    SocketEventsEnum.WALLET_UPDATE,
    (response: PlayerModels.SocketWalletUpdateResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(PlayerActions.setWalletBalance(response.payload.balance))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.RANKS_LEVELUP,
    (response: PlayerModels.SocketGamificationDetailsResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(
          globalUiActions.setNotificationSnackbar({
            open: true,
          }),
        )

        store.dispatch(
          globalUiActions.setModalType(NotificationTypeEnum.LEVEL_UP),
        )

        store.dispatch(PlayerActions.setGamificationDetails(response.payload))

        store.dispatch(
          PlayerActions.setEarnedRewards(response?.payload?.rewards || []),
        )

        if (
          response?.payload?.rewards.length > 0 &&
          store.getState().globalSettings.rewardClaimEnable
        ) {
          store.dispatch(
            MyRewardsActions.addChestAction({
              rewards: response?.payload?.rewards || [],
              trigger: RewardTriggerTypeEnum.LEVEL,
              groupId: response?.payload?.rewardsGroupId,
              playerId: response?.payload?.playerId,
            }),
          )
        }

        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.RANKS_RANKUP,
    (response: PlayerModels.SocketGamificationDetailsResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(
          globalUiActions.setNotificationSnackbar({
            open: true,
          }),
        )

        store.dispatch(
          globalUiActions.setModalType(NotificationTypeEnum.RANK_UP),
        )

        store.dispatch(PlayerActions.setGamificationDetails(response.payload))

        store.dispatch(
          PlayerActions.setEarnedRewards(response?.payload?.rewards || []),
        )

        if (
          response?.payload?.rewards.length > 0 &&
          store.getState().globalSettings.rewardClaimEnable
        ) {
          store.dispatch(
            MyRewardsActions.addChestAction({
              rewards: response?.payload?.rewards || [],
              trigger: RewardTriggerTypeEnum.RANK,
              groupId: response?.payload?.rewardsGroupId,
              playerId: response?.payload?.playerId,
            }),
          )
        }

        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.RANKS_XPUP,
    (response: PlayerModels.SocketGamificationDetailsResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(PlayerActions.setGamificationDetails(response.payload))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  // My Booster Sockets
  socket.on(
    SocketEventsEnum.TOKEN_BOOSTER_ACTIVATED,
    (response: MyBoosterModels.SocketTokenBoosterActivatedResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(
          MyBoosterActions.addActiveBooster({
            ...response.payload,
            boosterType: RewardTypeEnum.TOKEN_BOOSTER,
          }),
        )
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.TOKEN_BOOSTER_END,
    (response: MyBoosterModels.SocketTokenBoosterActivatedResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(MyBoosterActions.removeActiveBooster(response.payload))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.LEVEL_BOOSTER_ACTIVATED,
    (response: MyBoosterModels.SocketTokenBoosterActivatedResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(
          MyBoosterActions.addActiveBooster({
            ...response.payload,
            boosterType: RewardTypeEnum.LEVEL_BOOSTER,
          }),
        )
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.LEVEL_BOOSTER_END,
    (response: MyBoosterModels.SocketTokenBoosterActivatedResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(MyBoosterActions.removeActiveBooster(response.payload))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.MISSION_BOOSTER_ACTIVATED,
    (response: MyBoosterModels.SocketTokenBoosterActivatedResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(
          MyBoosterActions.addActiveBooster({
            ...response.payload,
            boosterType: RewardTypeEnum.MISSION_BOOSTER,
          }),
        )
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.MISSION_BOOSTER_END,
    (response: MyBoosterModels.SocketTokenBoosterActivatedResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(MyBoosterActions.removeActiveBooster(response.payload))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.OPT_IN,
    (response: PlayerModels.SocketGamificationOptStatusResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(
          PlayerActions.setGamificationOptStatus(
            response.payload.gamificationOpt.enable,
          ),
        )
        ackMessage(response.guarantee, response.messageId)
        store.dispatch(
          globalUiActions.setSnackbar({
            message: i18n.t('mechanicsSuccessOptInExternal'),
            open: true,
            severity: SnackbarSeverityEnum.SUCCESS,
          }),
        )
      }
    },
  )

  socket.on(
    SocketEventsEnum.OPT_OUT,
    (response: PlayerModels.SocketGamificationOptStatusResponse) => {
      if (response?.payload.playerId === playerId) {
        store.dispatch(
          PlayerActions.setGamificationOptStatus(
            response.payload.gamificationOpt.enable,
          ),
        )
        ackMessage(response.guarantee, response.messageId)
        store.dispatch(
          globalUiActions.setSnackbar({
            message: i18n.t('mechanicsSuccessOptOutExternal'),
            open: true,
            severity: SnackbarSeverityEnum.SUCCESS,
          }),
        )
      }
    },
  )

  socket.on(
    SocketEventsEnum.ONSITE_NOTIFICATION,
    (response: OnSiteModels.SocketOnSiteNotificationResponse) => {
      if (
        !store
          .getState()
          .onSite.onsiteNotifications.find(
            (notification) => notification.messageId === response.messageId,
          )
      ) {
        socket.emit(SocketEventsEnum.ONSITE_SEND_EVENT, {
          trackingType: TrackingTypeEnum.DELIVERED,
          message: response,
        })

        store.dispatch(OnSiteActions.addOnSiteNotification(response))

        if (!store.getState().globalUI.dialogOnSite.open) {
          store.dispatch(
            globalUiActions.setDialogOnSite({
              id: UIDialogsEnum.ON_SITE_NOTIFICATION,
              open: true,
            }),
          )

          socket.emit(SocketEventsEnum.ONSITE_SEND_EVENT, {
            trackingType: TrackingTypeEnum.OPENED,
            message: response,
          })
          ackMessage(response.guarantee, response.messageId)
        }
      }
    },
  )

  socket.on(
    SocketEventsEnum.MISSION_OBJECTIVE_UPDATE,
    (response: MissionHistoryModels.SocketUpdateObjectiveResponse) => {
      if (
        response?.payload.playerId === playerId &&
        store.getState().globalSettings.missionsEnable
      ) {
        store.dispatch(MissionHistoryActions.updateMissionObjective(response))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.MISSION_OBJECTIVE_COMPLETE,
    (response: MissionHistoryModels.SocketUpdateObjectiveResponse) => {
      if (
        response?.payload.playerId === playerId &&
        store.getState().globalSettings.missionsEnable
      ) {
        store.dispatch(MissionHistoryActions.updateMissionObjective(response))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.MISSION_UPDATE,
    (response: MissionHistoryModels.SocketUpdateMissionResponse) => {
      if (
        response?.payload.playerId === playerId &&
        store.getState().globalSettings.missionsEnable
      ) {
        store.dispatch(MissionHistoryActions.updateMission(response))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.MISSION_COMPLETE,
    (response: MissionHistoryModels.SocketUpdateMissionResponse) => {
      if (
        response?.payload.playerId === playerId &&
        store.getState().globalSettings.missionsEnable
      ) {
        store.dispatch(MissionHistoryActions.updateMission(response))

        if (response?.payload.missionBundleState) {
          store.dispatch(MissionHistoryActions.updateBundleState(response))

          const rewards =
            response?.payload?.rewardCategories?.filter(
              (category) => category.status === RewardStatusEnum.CLAIMED,
            )[0]?.rewards || []

          if (rewards.length > 0) {
            store.dispatch(
              MissionHistoryActions.setNotificationCompleted(response?.payload),
            )
            if (store.getState().globalSettings.rewardClaimEnable) {
              store.dispatch(
                MyRewardsThunks.getRewardsByTriggerThunk({
                  playerId: response?.payload?.playerId,
                  limit: 100,
                  page: 1,
                  status: RewardProcessorStatusEnum.UNCLAIMED,
                }),
              )
            }
          }
        }

        store.dispatch(
          globalUiActions.setNotificationSnackbar({
            open: true,
          }),
        )

        store.dispatch(
          globalUiActions.setModalType(NotificationTypeEnum.MISSION),
        )

        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.MISSION_BUNDLE_COMPLETE,
    (response: MissionHistoryModels.SocketUpdateMissionBundleResponse) => {
      if (
        response?.payload.playerId === playerId &&
        store.getState().globalSettings.missionsEnable
      ) {
        store.dispatch(MissionHistoryActions.updateReloadBundles(true))
        ackMessage(response.guarantee, response.messageId)
      }
    },
  )

  socket.on(
    SocketEventsEnum.RANKS_CAP_REACHED,
    (response: PlayerModels.SocketRanksCapReachedResponse) => {
      if (
        response?.payload.playerId === playerId &&
        response?.payload?.reached
      ) {
        store.dispatch(
          globalUiActions.setSnackbar({
            message: i18n.t('capReached', {
              periodType: response.payload.period,
              timeText: i18n.t(`timeText.${response.payload.period}`),
            }),
            open: true,
            severity: SnackbarSeverityEnum.WARNING,
          }),
        )
      }
    },
  )
}
