import { typographyClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ActiveBoostersColors, getBorderColorByMode } from '@/constants'

import { BoosterColorsType } from '@/state/my-booster/models'

interface Props {
  closedVersion?: boolean
  popoverItem?: boolean
  boosterType?: string
  boosterColors?: BoosterColorsType
  isNew?: boolean
  animationTime?: number
  animationBoosterFinished?: boolean
}

export const useStyles = makeStyles<
  Props,
  'content' | 'contentText' | 'wrapper'
>()((
  theme,
  {
    boosterType = 'warning',
    boosterColors,
    popoverItem,
    closedVersion = false,
    isNew = false,
    animationTime = 4,
    animationBoosterFinished,
  },
  classes,
) => {
  const isWarningAlertType =
    boosterType === 'warning' || boosterType === 'alert'

  const textColor = !isWarningAlertType
    ? boosterColors?.textColor || theme.palette.text.primary
    : ActiveBoostersColors[boosterType][theme.palette.mode].textColor

  const baseColor =
    !isWarningAlertType && boosterColors?.baseColor
      ? boosterColors.baseColor
      : ActiveBoostersColors[boosterType].baseColor

  const backgroundColor = isWarningAlertType
    ? ActiveBoostersColors[boosterType][theme.palette.mode].background
    : boosterColors?.background || theme.palette.background.default

  const borderColorModeOut = getBorderColorByMode(theme, 0.1, 0.2)

  const borderColorModeIn = getBorderColorByMode(theme, 0.1, 0.05)

  const moveAnimation = () => {
    return {
      animation: `zoomAnimation ${animationTime}s infinite`,
      transition: 'transform 0.5s ease-in-out',

      '@keyframes zoomAnimation': {
        '0%, 100%': {
          transform: 'scale(1)',
        },
        '50%': {
          transform: 'scale(1.05)',
        },
      },
    }
  }

  return {
    boostersContainer: {
      display: 'flex',
      justifyContent: closedVersion ? 'center' : 'left',
      flexDirection: closedVersion ? 'column-reverse' : 'row-reverse',
      minWidth: closedVersion ? 42 : 'auto',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left',
        flexDirection: 'row-reverse',
      },
    },
    skeleton: {
      height: 64,
      transform: 'scale(1)',
      marginBottom: 8,
    },
    isOpen: {
      [`& .${classes.content}`]: {
        maxWidth: 300,
        transition: 'all 500ms',
        padding: '0 10px 0 48px',
        marginRight: '0 !important',
      },
      [`& .${classes.contentText}`]: {
        opacity: 1,
        transition: 'all 750ms',
      },
    },
    popoverItem: {
      border: `1px solid ${getBorderColorByMode(theme, 0.08)}`,
      background: backgroundColor,
      borderRadius: 8,
      marginBottom: 8,
      width: '100%',
      height: 'auto',
    },
    wrapper: {
      width: 'auto',
      padding: popoverItem ? '10px' : 0,
    },
    box: {
      height: 42,
      width: 42,
      borderRadius: 42,
      position: 'absolute',
      zIndex: 1,
      ...(!!!animationBoosterFinished && {
        background: backgroundColor,
      }),
      '& .completed': {
        color: backgroundColor,
        position: 'absolute',
        top: 0,
        left: 0,
        border: `0.5px solid ${borderColorModeOut}`,
        borderRadius: 42,
      },

      '& .circular': {
        color: baseColor,
        strokeLinecap: 'round',
      },
    },
    contentText: {
      opacity: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      transition: 'all 750ms',
    },
    content: {
      color: textColor,
      height: 42,
      maxWidth: 42,
      borderRadius: 42,
      padding: 0,
      transition: 'all 500ms',
      overflow: 'hidden',
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
      ...(!isNew &&
        !!!animationBoosterFinished && {
          background: backgroundColor,
          border: !popoverItem ? `1px solid ${borderColorModeOut}` : 'none',
        }),
    },
    container: {
      width: 'auto',
      height: 42,
      borderRadius: 42,
      display: 'inline-block',
      whiteSpace: 'nowrap',
      position: 'relative',
      marginRight: -8,
    },

    clockIcon: {
      fontSize: 15,
      color: textColor,
    },

    rateContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
      border: boosterType !== 'alert' && '0.5px solid',
      borderColor: borderColorModeIn,
      borderRadius: '50%',

      '& svg': {
        width: 22,
        height: 22,
        margin: '0 auto',
        position: 'absolute',
        left: 5,
        top: 4,
        ...(boosterType === 'alert' && moveAnimation()),

        '& g path': {
          fill: isWarningAlertType
            ? `${ActiveBoostersColors[boosterType].baseColor} !important`
            : boosterColors?.baseColor && boosterColors?.baseColor,
        },
      },

      '& img': {
        width: 33,
        height: 33,
        marginLeft: -1,
        marginTop: -1,
      },

      '& .rate': {
        position: 'absolute',
        fontSize: 8,
        borderRadius: 6,
        textAlign: 'center',
        border: '1px solid',
        padding: 1,
        lineHeight: '12px',
        fontWeight: 700,
        minWidth: 22,
        bottom: -7,
        left: 12,
        borderColor: borderColorModeIn,
        color: isWarningAlertType ? theme.palette.common.white : textColor,
        background: isWarningAlertType
          ? ActiveBoostersColors[boosterType].baseColor
          : boosterColors?.baseColor || backgroundColor,
        ...(boosterType === 'alert' && {
          animation: `zoomRotateShakeAnimation ${animationTime / 2}s infinite`,
          transition: `transform ${animationTime / 2}s ease-in-out`,

          '@keyframes zoomRotateShakeAnimation': {
            '0%, 100%': {
              transform: 'scale(1) rotate(0deg) translateX(0) translateY(0)',
            },
            '10%, 30%, 50%, 70%, 90%': {
              transform:
                'scale(1.05) rotate(-1deg) translateX(-0.25px) translateY(-0.25px)',
            },
            '20%, 40%, 60%, 80%': {
              transform:
                'scale(1.05) rotate(1deg) translateX(0.25px) translateY(0.25px)',
            },
            '25%': {
              transform:
                'scale(1.05) rotate(-1deg) translateX(-0.25px) translateY(-0.25px)',
            },
            '50%': {
              transform: 'scale(1.05) rotate(0deg) translateX(0) translateY(0)',
            },
            '75%': {
              transform:
                'scale(1.05) rotate(1deg) translateX(0.25px) translateY(0.25px)',
            },
          },
        }),
      },
    },

    showAllItems: {
      padding: 0,
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      position: 'relative',
      borderRadius: '50%',
      minWidth: 42,
      width: 42,
      height: 42,
      pointerEvents: closedVersion ? 'none' : 'initial',

      [`& .${typographyClasses.body1}`]: {
        fontSize: 14,
        fontWeight: 600,
      },
    },

    noDataText: {
      color: theme.palette.text.secondary,
      fontStyle: 'italic',
      fontSize: closedVersion ? 10 : 12,
      textAlign: closedVersion ? 'center' : 'left',
    },
    zoomImage: {
      ...moveAnimation(),
    },
  }
})
