import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'
import { ActiveBoosterComponent } from '@/components/active-boosters'

import { TooltipComponent } from '@/components/common'
import { ReactComponent as IconFlash } from '@/assets/icons/common/icon-flash.svg'
import { useBreakpoints } from '@/hooks'

type Props = {
  Div: HTMLElement
  sidebarClient: string
  openSidebar: boolean
}

export const ActiveBoostersComponent = ({
  Div,
  sidebarClient,
  openSidebar,
}: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const { isMobile } = useBreakpoints()

  return (
    <Box className="activeItemsWrapper">
      <Box className="activeItemsTitle">
        {(!isMobile || (isMobile && openSidebar)) && (
          <Typography
            className="titleSection"
            fontStyle={sidebarClient === 'JP' ? 'italic' : 'normal'}
          >
            {t('boosters')}
          </Typography>
        )}
        {openSidebar && (
          <TooltipComponent
            title={t('boosters')}
            description={t('infoBoosters')}
            icon={
              <Box sx={{ '& svg path': { fill: theme.palette.primary.main } }}>
                <IconFlash />
              </Box>
            }
            infoSxProps={{ color: theme.palette.text.primary }}
          />
        )}
      </Box>
      <Box className="activeItemsContent">
        <ActiveBoosterComponent
          Div={Div}
          closedVersion={!openSidebar}
          isSidebarVersion
        />
      </Box>
    </Box>
  )
}
