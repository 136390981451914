import { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RewardStatusEnum } from '@gamanza-engage/mf-enums'
import { Box, Slide, Typography, useTheme } from '@mui/material'
import { ChestTypeEnum, VariantsComponentsEnum } from '@/enums'

import { GeneralModels, RootState } from '@/state'
import { useGlobalUi, useMissionObjectives } from '@/hooks'
import { ClaimChestItemComponent } from '@/components/common'

import { ModalRewardComponent } from '../'
import { useStyles } from '../styles'

type Props = {
  containerRef: MutableRefObject<any>
  isVisibleRewards: boolean
}

export const ModalMissionComponent = ({
  containerRef,
  isVisibleRewards,
}: Props) => {
  const { t } = useTranslation()

  const { modal } = useGlobalUi()

  const { rewardClaimEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { missionCompleted } = useSelector(
    ({ missionHistory }: RootState) => missionHistory,
  )

  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const { classes } = useStyles({ snackbarType: modal.type })

  const theme = useTheme()

  const rewards =
    missionCompleted?.rewardCategories?.filter(
      (category) => category.status === RewardStatusEnum.CLAIMED,
    )[0]?.rewards || []

  const { getObjective } = useMissionObjectives({
    objectives: missionCompleted?.objectives || [],
  })

  return (
    <>
      <Box mt={2}>
        <Typography
          className={classes.congratulationsSubtitle}
          fontWeight={600}
        >
          {t('objectivesCompletedMessage')}
        </Typography>
        <Slide
          direction="up"
          in={isVisibleRewards}
          container={containerRef.current}
          timeout={1500}
        >
          <Box className={classes.objectivesList}>
            {missionCompleted?.objectives?.map((objective) => {
              return (
                <Box className={classes.objectiveContent} key={objective._id}>
                  <img
                    src={`${baseUrl}assets/icons/missions/icon-objective-completed.svg`}
                    alt="Objective Completed"
                  />
                  <Typography variant="body2">
                    {getObjective(objective)}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        </Slide>
      </Box>
      {rewards.length > 0 && (
        <Box className={classes.rewards}>
          <Slide
            direction="up"
            in={isVisibleRewards}
            container={containerRef.current}
            timeout={2000}
          >
            <Box width="100%" mt={1}>
              <Typography
                className={classes.congratulationsSubtitle}
                fontWeight={600}
                mb={1}
              >
                {t('congratulationsMissionRewards')}
              </Typography>
              {rewardClaimEnable ? (
                <ClaimChestItemComponent
                  rewards={rewards}
                  variant={VariantsComponentsEnum.LARGE}
                  chestType={ChestTypeEnum.MISSION}
                  title={t('missionReward')}
                  buttonColor={theme.palette.primary.main}
                  missionId={missionCompleted._id}
                  bundleId={missionCompleted.missionBundleId}
                />
              ) : (
                <Box className={classes.rewardList}>
                  {rewards.map((reward: GeneralModels.RewardCategoryType) => (
                    <ModalRewardComponent reward={reward} key={reward.id} />
                  ))}
                </Box>
              )}
            </Box>
          </Slide>
        </Box>
      )}
    </>
  )
}
