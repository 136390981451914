import { makeStyles } from 'tss-react/mui'
import { cardActionAreaClasses, darken, lighten } from '@mui/material'
import { wysiwygTextProps } from '@/constants'

interface Props {
  client: string
}

export const useStyles = makeStyles<Props>()((theme, { client }) => {
  return {
    card: {
      borderRadius: 8,
      [`& .${cardActionAreaClasses.root}`]: {
        height: 'auto',
      },
    },
    cardContent: {
      padding: 10,
    },
    imageContainer: {
      position: 'relative',
      paddingTop: '68.71%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? lighten(theme.palette.background.default, 0.1)
          : darken(theme.palette.background.paper, 0.05),
      width: '100%',
    },
    cardMedia: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      objectPosition: 'center',
      transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    title: {
      fontWeight: client === 'JP' ? 400 : 600,
      fontSize: client === 'JP' ? 16 : 14,
      lineHeight: client === 'JP' ? 1.2 : 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical',
      minHeight: 18,
    },
    description: {
      fontSize: 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      ...wysiwygTextProps,
      '& p, & ul, & ol': {
        ...wysiwygTextProps['& p, & ul, & ol'],
        margin: 0,
      },
    },
    imageChips: {
      position: 'absolute',
      bottom: 4,
      left: 4,
    },
  }
})
