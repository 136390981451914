import { useState } from 'react'
import { Box, SxProps, Typography, useTheme } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'

import { HtmlTooltip } from '@/theme/tooltip.theme'

type Props = {
  title: string
  description: string
  icon?: React.ReactNode
  maxWidth?: number
  infoSxProps?: SxProps
}

export const TooltipComponent = ({
  title,
  description,
  icon,
  maxWidth,
  infoSxProps,
}: Props) => {
  const theme = useTheme()

  const [isOpenedTooltip, setIsOpenedTooltip] = useState(false)

  return (
    <HtmlTooltip
      open={isOpenedTooltip}
      onClose={() => setIsOpenedTooltip(false)}
      onClick={() => setIsOpenedTooltip(true)}
      onMouseEnter={() => setIsOpenedTooltip(true)}
      maxwidth={maxWidth}
      title={
        <Box display="flex" gap={1}>
          {!!icon && icon}
          <Box display="flex" flexDirection="column">
            <Typography variant="body2" fontWeight={700}>
              {title}
            </Typography>
            <Typography variant="caption">{description}</Typography>
          </Box>
        </Box>
      }
      placement="top-end"
    >
      <InfoOutlined
        sx={{
          color: theme.palette.primary.main,
          ...infoSxProps,
        }}
        fontSize="inherit"
      />
    </HtmlTooltip>
  )
}
