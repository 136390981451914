import { useCallback } from 'react'
import {
  MissionHistoryModels,
  MissionHistoryThunks,
  MyRewardsActions,
  MyRewardsModels,
  PlayerModels,
  PlayerThunks,
  useAppDispatch,
} from '@/state'

export const useRewardActions = () => {
  const dispatch = useAppDispatch()

  const claimMissionRewards = useCallback(
    (payload: MissionHistoryModels.ClaimMissionRewardsPayload) => {
      dispatch(MissionHistoryThunks.claimMissionRewardsThunk(payload))
    },
    [dispatch],
  )

  const claimPlayerRewards = useCallback(
    (payload: PlayerModels.ClaimPlayerRewardsPayload) => {
      dispatch(PlayerThunks.claimPlayerRewardsThunk(payload))
    },
    [dispatch],
  )

  const removeChestByGroupId = useCallback(
    (payload: MyRewardsModels.RemoveRewardsByGroupIdType) => {
      dispatch(MyRewardsActions.removeChestByGroupIdAction(payload))
    },
    [dispatch],
  )

  const removeChestByMissionId = useCallback(
    (payload: MyRewardsModels.RemoveRewardsByMissionIdType) => {
      dispatch(MyRewardsActions.removeChestByMissionIdAction(payload))
    },
    [dispatch],
  )

  return {
    claimMissionRewards,
    claimPlayerRewards,
    removeChestByGroupId,
    removeChestByMissionId,
  }
}
