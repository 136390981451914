import { MutableRefObject, useEffect, useState } from 'react'

type Props = {
  contentRef: MutableRefObject<HTMLDivElement>
}

export const useMissionsStandardScroll = ({ contentRef }: Props) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  const handleNext = () => {
    const contentElement = contentRef.current
    if (contentElement) {
      contentElement.scrollBy({ left: 240, behavior: 'smooth' })
    }
  }

  const handleBack = () => {
    const contentElement = contentRef.current
    if (contentElement) {
      contentElement.scrollBy({ left: -240, behavior: 'smooth' })
    }
  }

  const updateArrowVisibility = () => {
    const contentElement = contentRef.current
    if (contentElement) {
      const showLeftArrow = contentElement.scrollLeft > 0
      const showRightArrow =
        contentElement.scrollWidth >
        contentElement.clientWidth + contentElement.scrollLeft

      setShowLeftArrow(showLeftArrow)
      setShowRightArrow(showRightArrow)
    }
  }

  useEffect(() => {
    const contentElement = contentRef.current

    if (contentElement) {
      const initialUpdate = () => {
        setTimeout(updateArrowVisibility, 100)
      }

      const resizeObserver = new ResizeObserver(() => {
        updateArrowVisibility()
      })

      resizeObserver.observe(contentElement)

      contentElement.addEventListener('scroll', updateArrowVisibility)

      initialUpdate()

      return () => {
        resizeObserver.disconnect()
        contentElement.removeEventListener('scroll', updateArrowVisibility)
      }
    }
  }, [contentRef]) //eslint-disable-line

  useEffect(() => {
    const contentElement = contentRef.current
    if (contentElement) {
      setTimeout(updateArrowVisibility, 0)
    }
  }, []) //eslint-disable-line

  return {
    showLeftArrow,
    showRightArrow,
    handleNext,
    handleBack,
  }
}
