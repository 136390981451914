export const optin = {
  mechanicsCTAOptIn: 'Opt-In',
  mechanicsCTAOptOut: 'Opt-Out',
  mechanicsDescriptionOptOut:
    'You can deactivate all loyalty features by choosing to opt out. You will no longer accumulate XP Points, Tokens or progress through levels. You will not be able to access the Reward Shop or buy any products, and any purchased item(s) pending activation will be lost with no return.',
  mechanicsDescriptionOptIn:
    'If you want to voluntarily reactivate the gamification functions, you can request it here',
  mechanicsDisableState:
    'Not available because you opted out of the Loyalty Program. Please contact customer support for more information.',
  mechanicsModalCTAOptIn: 'Reactivate',
  mechanicsModalCTAOptOut: 'Deactivate',
  mechanicsModalConfirmOptIn:
    'I confirm I want to reactivate loyalty features and start receiving benefits again.',
  mechanicsModalConfirmOptOut:
    'I confirm that I wish to opt out of all loyalty features, including access to the rewards store. I will not accumulate XP Points or Tokens.',
  mechanicsModalTitleOptIn: 'Reactivate Loyalty Features',
  mechanicsModalTitleOptOut: 'Deactivate Loyalty Features',
  mechanicsModalSubtitleOptIn:
    'Please confirm that you want to reactivate all loyalty features.',
  mechanicsModalSubtitleOptOut:
    'Please confirm that you want to deactivate all loyalty features.',
  mechanicsSuccessOptIn:
    'The process of reactivating the gamification functions was carried out successfully.',
  mechanicsSuccessOptOut:
    'The process of excluding the gamification functions was carried out successfully.',
  mechanicsSuccessOptInExternal:
    'You have been opted in to all gamification features.',
  mechanicsSuccessOptOutExternal:
    'You have been opted out of all gamification features.',
  mechanicsWarning:
    'Upon deactivation, all accumulated XP Points and Tokens, purchased items, as well as your current level, will be automatically lost and cannot be recovered.',
  writeReason: 'Write a reason here',
}
