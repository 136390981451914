import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { RootState } from '@/state'

import {
  DialogActivateBoosterComponent,
  DialogListBoostersComponent,
} from '@/components/my-boosters'
import { useGlobalUi } from '@/hooks'
import { UIDialogsEnum } from '@/enums'

import { useSidebarActiveBoosters } from '@/components/sidebar/hooks'
import {
  ActiveMissionsComponent,
  ActiveBoostersComponent,
  ActiveRewardsComponent,
} from './'
import { useHomeStyles } from './style'

type Props = {
  Div: HTMLElement
  sidebarClient: string
  openSidebar: boolean
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const SidebarHomeComponent = ({
  Div,
  sidebarClient,
  openSidebar,
  handleChangeMenuItem,
}: Props) => {
  const { activeBoosters } = useSidebarActiveBoosters()

  const { classes } = useHomeStyles({
    openSidebar,
    isActiveBoosters: activeBoosters?.length === 0,
  })

  const { missionsEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { dialog } = useGlobalUi()

  return (
    <Box className={classes.homeContent}>
      {activeBoosters.length > 0 && (
        <ActiveBoostersComponent
          Div={Div}
          sidebarClient={sidebarClient}
          openSidebar={openSidebar}
        />
      )}
      <ActiveRewardsComponent
        sidebarClient={sidebarClient}
        openSidebar={openSidebar}
      />
      {missionsEnable && (
        <ActiveMissionsComponent
          sidebarClient={sidebarClient}
          openSidebar={openSidebar}
          handleChangeMenuItem={handleChangeMenuItem}
        />
      )}

      {dialog.open && dialog.id === UIDialogsEnum.LIST_MY_BOOSTERS && (
        <DialogListBoostersComponent />
      )}
      {dialog.open && dialog.id === UIDialogsEnum.ACTIVATE_BOOSTER && (
        <DialogActivateBoosterComponent dense={true} />
      )}
    </Box>
  )
}
