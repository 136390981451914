import { autocompleteClasses } from '@mui/material'

export const inputBaseTheme = {
  input: {
    height: 56,
    [`&.${autocompleteClasses.input}`]: {
      height: 'auto',
    },
  },
}
