import { makeStyles } from 'tss-react/mui'
import { alpha, linearProgressClasses, typographyClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

interface Props {
  progressColor?: string
  heightProgress?: number
  amountLinearBars?: number
}

export const useProgressBarStyles = makeStyles<Props>()((
  theme,
  { progressColor, heightProgress, amountLinearBars },
) => {
  const heightProgressBar = heightProgress | 16

  return {
    progressWrapper: {
      display: 'flex',
      border: `1px solid ${getBorderColorByMode(theme)}`,
      borderRadius: heightProgressBar,
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
    },

    progressContainer: {
      height: heightProgressBar,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      borderRadius: heightProgressBar,
      zIndex: 1,

      '& .linearBars': {
        display: 'flex',
        width: '100%',
        position: 'absolute',
        height: heightProgressBar,
        zIndex: 1,

        '& .linearBar': {
          width: amountLinearBars
            ? `calc(100% / ${amountLinearBars})`
            : 'calc(100% / 3)',
          borderRight: '1px solid',
          borderColor: alpha(theme.palette.text.primary, 0.05),
        },

        '& .linearBar:last-child': {
          border: 'none',
        },
      },

      [`& .${linearProgressClasses.root}`]: {
        width: '100%',
        height: heightProgressBar,
        borderRadius: heightProgressBar,
        backgroundColor: alpha(theme.palette.text.primary, 0.3),

        [`& .${linearProgressClasses.bar}`]: {
          border: `1px solid ${progressColor}`,
          borderRadius: heightProgressBar,
          backgroundColor: progressColor || theme.palette.primary.main,

          '&::before': {
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            margin: '0px auto',
            borderTop: `${heightProgressBar / 2}px solid ${alpha(theme.palette.common.white, 0.26)}`,
            borderTopLeftRadius: heightProgressBar,
            borderTopRightRadius: heightProgressBar,
            zIndex: 1,
            content: '""',
          },
        },
      },

      '& .balance': {
        [`& .${typographyClasses.root}`]: {
          fontSize: 12,
          lineHeight: 1.2,
          fontWeight: 700,
          color: theme.palette.common.white,
          WebkitTextStroke: `2px ${theme.palette.common.black}`,
          paintOrder: 'stroke fill',
        },
        position: 'absolute',
        textAlign: 'center',
        zIndex: 2,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  }
})
