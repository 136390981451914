import { useSelector } from 'react-redux'
import {
  Box,
  BoxProps,
  SxProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material'
import { RootState } from '@/state'

import { TooltipComponent } from '@/components/common'
import {
  typographyCaptionPointsProps,
  typographyH6PointsProps,
} from '@/constants'

type PointCardProps = {
  pointContent?: React.ReactNode
  icon?: React.ReactNode
  value?: string | number
  name?: string
  tooltipText?: string
  progress?: React.ReactNode
  sx?: SxProps
  titleProps?: TypographyProps
  valueProps?: TypographyProps
}

export function PointCardComponent({
  pointContent,
  icon,
  value,
  name,
  tooltipText,
  progress,
  sx,
  titleProps,
  valueProps,
}: PointCardProps) {
  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const theme = useTheme()

  const iconPointsProps: BoxProps = {
    padding: 1,
    borderRadius: 2,
    width: '100%',
    bgcolor: theme.palette.background.default,
    flex: '1 0 0',
    minWidth: 0,
  }

  const imageIcon = (
    <Box
      className="imageIcon"
      display="flex"
      justifyContent="center"
      height={36}
    >
      {icon}
    </Box>
  )

  return (
    <Box className="pointWrapper" {...iconPointsProps}>
      <Box sx={sx}>
        {pointContent || (
          <>
            {imageIcon}
            {!!name && (
              <Box position="relative" display="inline-block">
                <Typography
                  variant="h6"
                  sx={typographyH6PointsProps(theme, titleProps)}
                >
                  {name}
                </Typography>
                {!!tooltipText && (
                  <TooltipComponent
                    title={name}
                    description={tooltipText}
                    icon={<Box width={30}>{imageIcon}</Box>}
                    infoSxProps={{
                      position: 'absolute',
                      top: '50%',
                      right: -18,
                      transform: 'translate(0, -50%)',
                    }}
                  />
                )}
              </Box>
            )}
          </>
        )}
      </Box>

      {!!value && (
        <Typography
          variant="caption"
          component="p"
          sx={typographyCaptionPointsProps(valueProps)}
        >
          {gamificationOpt?.enable ? value : '--'}
        </Typography>
      )}

      {progress}
    </Box>
  )
}
