export const translation = {
  activate: 'Activate',
  activateBooster: 'Activate Booster',
  activatedSuccessfully: 'Activated successfully.',
  activeBoosterDescription:
    'If you activate this Booster, it will show when launching a game. Otherwise, it will show as Pending under My Boosters. You may re-position the booster by holding and dragging it.',
  back: 'Back',
  boosters: 'Boosters',
  by: 'by',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  // TODO caps will be added later
  // capReached: `Your {{periodType}} Tokens Cap has been reached. For {{timeText}}, you will not accumulate further tokens.`,
  // capReachedBoosterError: `You cannot activate this Tokens Booster {{timeText}}, because your {{periodType}} Tokens Cap was already reached. Please try again {{nextTimeText}}.`,
  clickToOpen: 'Click to open',
  confirmActivateBooster: 'Do you want to activate the booster?',
  congratulations: 'Congratulations!',
  congratulationsLevelUp: `You’ve reached level {{level}} now`,
  congratulationsRankUp: `You’ve reached rank {{rank}} now`,
  congratulationsRewards: `Congratulations! You've leveled up to {{type}}! You earned the following rewards:`,
  congratulationsWithoutRewards: `Congratulations! You've leveled up to {{type}}!`,
  error: 'Error',
  errorLoading: 'Connection problems.',
  errorOccurred: 'An error has occurred. Please try again.',
  forNextLevel: 'for the next level!!',
  forNextRank: 'for the next rank!!',
  goBack: 'Go Back',
  goHome: 'Go home',
  home: 'Home',
  id: 'Id',
  important: 'Important',
  infoBoosters: 'This is the tooltip text for boosters.',
  infoLevel: 'This is the tooltip text for levels.',
  infoMyRewards: 'This is the tooltip text for my rewards.',
  infoRank: 'This is the tooltip text for ranks.',
  infoTokens: 'This is the tooltip text for tokens.',
  infoXPPoints: 'This is the tooltip text for xp points.',
  level: 'Level',
  levelBooster: 'Level Booster',
  levelReward: 'Level Reward',
  loyaltyProgram: 'Loyalty Program',
  myBoosters: 'My Boosters',
  myRewards: 'My Rewards',
  name: 'Name',
  next: 'Next',
  noActiveBoosters: 'There are no active boosters', // delete
  noBoostersAvailable: 'No boosters available',
  noBoostersYet: 'No Boosters Yet',
  open: 'Open',
  other: 'Other',
  pending: 'Pending',
  prev: 'Prev',
  quantity: 'Quantity',
  rank: 'Rank',
  rankReward: 'Rank Reward',
  seeAll: 'See All',
  seeDetails: 'See Details',
  seeMore: 'See more',
  seeProgress: 'See Progress',
  skip: 'Skip',
  sports: 'Sports',
  start: 'Start',
  status: 'Status',
  success: 'Success',
  summary: 'Summary',
  teams: 'Teams',
  tokenBooster: 'Token Booster',
  tokens: 'Tokens',
  total: 'Total',
  tournaments: 'Tournaments',
  tryAgain: 'Try Again',
  value: 'Value',
  wonChest: "You've won a chest! Open it to reveal amazing prizes.",
  xpPoints: 'XP Points',
  yesActivate: 'Yes, activate',
}
