import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'
import { MyRewardsComponent } from '@/components/my-rewards'

import { TooltipComponent } from '@/components/common'
import { ReactComponent as IconBag } from '@/assets/icons/common/icon-bag.svg'
import { useBreakpoints } from '@/hooks'

type Props = {
  sidebarClient: string
  openSidebar: boolean
}

export const ActiveRewardsComponent = ({
  sidebarClient,
  openSidebar,
}: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const { isMobile } = useBreakpoints()

  return (
    <Box className="activeItemsWrapper">
      <Box className="activeItemsTitle">
        {(!isMobile || (isMobile && openSidebar)) && (
          <Typography
            className="titleSection"
            fontStyle={sidebarClient === 'JP' ? 'italic' : 'normal'}
          >
            {t(openSidebar ? 'myRewards' : 'rewards')}
          </Typography>
        )}
        {openSidebar && (
          <TooltipComponent
            title={t('myRewards')}
            description={t('infoMyRewards')}
            icon={<IconBag />}
            infoSxProps={{ color: theme.palette.text.primary }}
          />
        )}
      </Box>
      <MyRewardsComponent closedVersion={!openSidebar} />
    </Box>
  )
}
