import { t } from 'i18next'
import {
  getHours,
  getYear,
  getMinutes,
  format,
  addMilliseconds,
} from 'date-fns'
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz'

import {
  TimeZoneEnum,
  TimeZoneUTCEnum,
  ValidityTimeEnum,
} from '@gamanza-engage/mf-enums'

export const formatDateInUTC = (dateString: string, dateFormat: string) => {
  const utcDate = getDateTimeFromIsoUTC(
    dateString,
    TimeZoneUTCEnum['GMT+00 Coordinated Universal Time'],
  )
  return format(utcDate, dateFormat)
}

export const getDateTimeFromIsoUTC = (
  isoDate: string,
  timeZone: TimeZoneUTCEnum,
) => {
  return utcToZonedTime(new Date(isoDate), timeZone)
}

export const getInvalidYear = (date: string) => {
  return getYear(new Date(date)) < 2021
}

export const getTimeForToday = (time: string): Date => {
  const hours = Number(time.split(':')[0])
  const minutes = Number(time.split(':')[1])
  const seconds = Number(time.split(':')[2])
  return new Date(new Date().setHours(hours, minutes, seconds))
}

export const getTimeFromDate = (date: Date): string => {
  const hours = getHours(date)
  const minutes = getMinutes(date)

  const hoursFormat = hours < 10 ? `0${hours}` : hours
  const minutesFormat = minutes < 10 ? `0${minutes}` : minutes

  const time = `${hoursFormat}:${minutesFormat}:00`
  return time
}

export const getConvertedTime = (
  time: string,
  timezone: string,
  saved?: boolean,
) => {
  const number = parseInt(timezone.slice(0, -2).substring(1), 10)
  const hours = Number(time.split(':')[0])
  const minutes = time.split(':')[1]
  const seconds = time.split(':')[2] || '00'

  let hoursZoned = saved ? hours - number : hours + number
  if (timezone.includes('+')) {
    hoursZoned = saved ? hours + number : hours - number
  }
  if (hoursZoned >= 24) {
    hoursZoned -= 24
  }
  if (hoursZoned < 0) {
    hoursZoned += 24
  }
  const hoursFormat = hoursZoned < 10 ? `0${hoursZoned}` : hoursZoned
  return `${hoursFormat}:${minutes}:${seconds}`
}

export const convertTimeFrame = (
  timeFrame: string,
): { time: string; unit: string } => {
  let text = timeFrame.replace(/[0-9]/g, '').replace('P', '')
  const num = timeFrame.replace(/[a-zA-Z]/g, '')

  if (text.includes('T')) {
    text = text.toLowerCase()
  }

  return {
    time: num,
    unit: text.replace('t', '').replace('.', ''),
  }
}

export const getDateWithOffset = (date: string) => {
  const _timezone = TimeZoneEnum.GMT_0
  const offset = getTimezoneOffset(TimeZoneUTCEnum[_timezone])
  const newDate = addMilliseconds(new Date(date.replace('Z', '')), offset)

  return `${format(newDate, 'dd/MM/yyyy - HH:mm')} ${_timezone?.slice(0, 6)}`
}

export const getExpirationText = (booster): string[] => {
  const exp = booster?.expirationValidityTime
  let expiration: string[]

  switch (exp?.timeType) {
    case ValidityTimeEnum.PERIOD:
      expiration = [
        `${t('expires')}: `,
        `${exp?.periodValue || '-'} ${t('timeUnitsText.' + exp?.periodType)}`,
      ]
      break
    case ValidityTimeEnum.SPECIFIC_DATE:
      expiration = [
        `${t('expires')}: `,
        `${!!exp?.date ? (getDateWithOffset(exp?.date) as string) : '-'}`,
      ]
      break
    default:
      expiration = []
      break
  }

  return expiration
}
