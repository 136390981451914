import {
  MissionEventTypeEnum,
  MissionNextLevelTypeEnum,
  MissionObjectiveConditionTypeEnum,
} from '@/enums'

export const missions = {
  channels: 'Channels',
  channelType: {
    email: 'Email',
    sms: 'SMS',
    phone: 'Phone',
    post: 'Post',
  },
  completed: 'Completed',
  conditionAmounts: 'Condition Amounts',
  congratulationsMissionRewards: 'You have earned the following rewards:',
  find: 'Find',
  gameCategories: 'Game Categories',
  gameProviders: 'Game Providers',
  games: 'Games',
  godsonAccountStatus: 'Godson Account Status',
  godsonAccountStatusType: {
    INITIAL: 'Initial',
    PROVISIONAL: 'Provisional',
    ACTIVE: 'Active',
    FULLY_IDENTIFIED: 'Fully Identified',
  },
  history: 'History',
  lastNumberDays: 'Last {{number}} days',
  markets: 'Markets',
  messageMissionCompleted: 'You just completed a Mission!',
  messageMissionEarnedRewards: `Great job, you've earned your rewards!`,
  minimumAmounts: 'Minimum Amounts',

  missionBooster: 'Mission Booster',
  missionCompleted: 'Mission Completed',
  missionConditionType: {
    [MissionObjectiveConditionTypeEnum.QUANTITY]: 'Quantity',
    [MissionObjectiveConditionTypeEnum.TOTAL_BET_AMOUNT]: 'Total Bet Amount',
    [MissionObjectiveConditionTypeEnum.SUCCESSIVE_QUANTITY]:
      'Successive Quantity',
    [MissionObjectiveConditionTypeEnum.TOTAL_WIN_AMOUNT]: 'Total Win Amount',
  },
  missionEventType: {
    [MissionEventTypeEnum.COMPLETED_DEPOSIT]: 'Completed Deposit',
    [MissionEventTypeEnum.COMPLETED_WITHDRAWAL]: 'Completed Withdrawal',
    [MissionEventTypeEnum.WAGER]: 'Casino Wager',
    [MissionEventTypeEnum.WIN]: 'Casino Win',
    [MissionEventTypeEnum.REFER_A_FRIEND]: 'Refer a friend',
    [MissionEventTypeEnum.LOGIN]: 'Login',
    [MissionEventTypeEnum.ACTIVATE_ACCOUNT]: 'Activate Account (KYC)',
    [MissionEventTypeEnum.ACCOUNT_VERIFICATION]: 'Account Verification',
    [MissionEventTypeEnum.SELF_ASSESSMENT_TEST]: 'Self Assessment Test',
    [MissionEventTypeEnum.OPT_IN]: 'Marketing Preferences',
    [MissionEventTypeEnum.LEVEL_UP]: 'Level Up',
    [MissionEventTypeEnum.SPORT_WAGER]: 'Sport Wager',
    [MissionEventTypeEnum.SPORT_WIN]: 'Sport Win',
  },
  missionHistory: 'Mission History',
  missionIncompleted: 'Mission Incompleted',
  missionLevelType: {
    [MissionNextLevelTypeEnum.ANY]: 'Any',
    [MissionNextLevelTypeEnum.SPECIFIC_LEVEL]: 'Specific Level',
  },
  missionLocked: 'Mission Locked',
  missionProgress: 'Mission Progress',
  missionReward: 'Mission Reward',
  missionRewards: 'Mission Rewards',
  missions: 'Missions',
  missionsHistory: 'Missions History',
  missionStatusType: {
    completed: 'Completed',
    locked: 'Locked',
    claim: 'Claim',
    going: 'Going',
    incomplete: 'Incomplete',
  },
  noMissions: 'No Missions',
  noMissionsMessage:
    'There are no missions for now, but the excitement never stops. Keep playing and come back soon!',
  noMissionsHistory: 'No Missions History',
  noMissionsHistoryMessage: `It looks like you haven't joined any tournaments yet. Once you do, you'll see all your results and achievements here.`,
  objectives: 'Objectives',
  objectivesCompletedMessage: 'You completed the following objectives:',
  progress: 'Progress',

  /// PENDING ADD TO DOCUMENTATION ///

  easterEgg: 'Easter Egg',
  easterEggDialogSubtitle: 'OH NO!!',
  easterEggDialogMessage: `We apologize, but this Easter Egg can't be claimed yet.  Please complete more Missions and try again later.`,
}
