import { makeStyles } from 'tss-react/mui'

interface Props {
  imageIconSize: number
  openSidebar: boolean
  isMobile: boolean
}

export const usePointsStyles = makeStyles<Props>()((
  theme,
  { imageIconSize, openSidebar, isMobile },
) => {
  return {
    pointsCardsContent: {
      position: 'absolute',
      zIndex: 2,
      left: 0,
      right: 0,
      top: openSidebar ? 95 : 90,
      margin: '0 auto',
      width: openSidebar ? 'calc(100% - 30px)' : 72,
      maxWidth: 400,
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'space-between',
      gap: openSidebar ? 8 : 6,
      flexDirection: openSidebar ? 'row' : 'column',
      opacity: openSidebar && !isMobile ? 0 : 1,
      animation:
        openSidebar && !isMobile
          ? 'flexAnimationOpacity 0.35s ease-in forwards 0.3s'
          : 'none',

      '@-moz-document url-prefix()': {
        width: openSidebar ? 'calc(100% - 30px)' : 65,
      },

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left',
        flexWrap: 'wrap',
        top: openSidebar ? 95 : 15,
        marginLeft: openSidebar ? '0 auto' : 95,
        width: openSidebar ? 'calc(100% - 30px)' : 70,
      },

      '& .pointWrapper': {
        boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)',
        padding: openSidebar ? 8 : 4,
      },

      '& .imageIcon': {
        height: imageIconSize,
      },
    },
  }
})
