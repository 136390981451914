import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { SettingsEnum } from '@gamanza-engage/mf-enums'
import {
  GlobalSettingsModels,
  GlobalSettingsThunks,
  PlayerModels,
} from '@/state'

export const getGlobalSettingsExtraReducer = (
  builder: ActionReducerMapBuilder<GlobalSettingsModels.GlobalSettingsState>,
) => {
  builder.addCase(
    GlobalSettingsThunks.getGlobalSettingsThunk.fulfilled,
    (state, { payload }) => {
      const reasons = Object.values(payload).find(
        (item) => item.key === SettingsEnum.OPT_OUT_REASONS,
      )

      const assetsRoute = Object.values(payload).find(
        (item) => item.key === SettingsEnum.ASSETS_ROUTE,
      )

      const missionsEnable = Object.values(payload).find(
        (item) => item.key === SettingsEnum.MISSIONS_ENABLE,
      )

      const gamificationEnable = Object.values(payload).find(
        (item) => item.key === SettingsEnum.GAMIFICATION_ENABLE,
      )

      const boostersEnable = Object.values(payload).find(
        (item) => item.key === SettingsEnum.BOOSTERS_ENABLE,
      )

      const rewardClaimEnable = Object.values(payload).find(
        (item) => item.key === SettingsEnum.REWARD_CLAIM_ENABLE,
      )

      state.assetsRoute = (assetsRoute?.value as string) || state.assetsRoute
      state.boostersEnable = (boostersEnable?.value as boolean) || false
      state.gamificationEnable = (gamificationEnable?.value as boolean) || false
      state.gamificationReasons =
        (reasons?.value as PlayerModels.ReasonOptStatusType[]) ||
        state.gamificationReasons
      state.missionsEnable = (missionsEnable?.value as boolean) || false
      state.rewardClaimEnable = (rewardClaimEnable?.value as boolean) || false
    },
  )
}
