import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { RootState } from '@/state'
import { useBreakpoints } from '@/hooks'
import {
  SidebarHeaderComponent,
  SidebarMenuComponent,
  SidebarContentComponent,
  useGlobalStyles,
} from './'

import { useSidebarActions } from '../hooks'

type Props = {
  Div: HTMLElement
}

export const SidebarComponent = ({ Div }: Props) => {
  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const contentBackground = Div.getAttribute('data-content-background') || ''

  const {
    openSidebar,
    sidebarMenuItem,
    fullContent,
    handleOpenSidebar,
    handleChangeMenuItem,
    handleSetFullContent,
  } = useSidebarActions()

  const { classes } = useGlobalStyles({
    openSidebar,
    contentBackground,
  })

  const { isMobile } = useBreakpoints()

  const getOverflow = () => {
    if (isMobile) {
      return openSidebar ? 'hidden auto' : 'auto hidden'
    }
    return 'hidden auto'
  }

  const [height, setHeight] = useState('auto')

  useEffect(() => {
    if (Div) {
      // Set up a MutationObserver to observe attribute changes
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === 'attributes') {
            const dataHeight = Div.getAttribute('data-height')
            setHeight(dataHeight || 'auto') // Update state with new attribute value
          }
        }
      })

      // Start observing the element for attribute changes
      observer.observe(Div, {
        attributes: true, // Observe attribute changes
      })

      // Initial attribute read

      const dataHeightValue = Div.getAttribute('data-height')
      setHeight(dataHeightValue || 'auto')

      // Cleanup observer on component unmount
      return () => {
        observer.disconnect()
      }
    }
  }, [openSidebar]) // eslint-disable-line

  return (
    <Box height={isMobile ? (openSidebar ? height : 'auto') : height}>
      <Box className={classes.sidebarWrapper}>
        <Box overflow={getOverflow} height="100%" borderRadius="8px 8px 0 0">
          <Box display={fullContent ? 'none' : 'block'}>
            <SidebarHeaderComponent
              Div={Div}
              sidebarClient={client}
              sidebarMenuItem={sidebarMenuItem}
              handleOpenSidebar={handleOpenSidebar}
              openSidebar={openSidebar}
            />
          </Box>
          <SidebarContentComponent
            Div={Div}
            sidebarClient={client}
            contentBackground={contentBackground}
            sidebarMenuItem={sidebarMenuItem}
            openSidebar={openSidebar}
            handleChangeMenuItem={handleChangeMenuItem}
            handleSetFullContent={handleSetFullContent}
          />
        </Box>
        {openSidebar && (
          <SidebarMenuComponent
            sidebarMenuItem={sidebarMenuItem}
            handleChangeMenuItem={handleChangeMenuItem}
          />
        )}
      </Box>
    </Box>
  )
}
