import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'

import { Box, Skeleton, Typography } from '@mui/material'
import { AccessTimeOutlined } from '@mui/icons-material'
import { GeneralModels, RootState } from '@/state'

import { ActiveBoosterType } from '@/state/my-booster/models'
import { getAssetsUrl, getLottieAnimationData } from '@/utils'
import { useStyles } from './style'

import { CircularProgressComponent } from './circular-progress.component'
import { useActiveBoostersItem } from '../hooks'
import {
  BreakingBoosterAnimationComponent,
  NewActiveBoosterItemComponent,
} from './'

type Props = {
  Div: HTMLElement
  booster: ActiveBoosterType
  index?: number
  popoverItem?: boolean
  handleCurrentBooster?: (currentId: string) => void
  currentBooster?: string
  closedVersion?: boolean
}

export function ActiveBoosterItemComponent({
  Div,
  index,
  booster,
  popoverItem = false,
  handleCurrentBooster,
  currentBooster,
  closedVersion = false,
}: Props) {
  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const {
    progress,
    timeRemaining,
    percentageProgress,
    getBoosterPercentageColor,
    boosterData,
    animationBoosterFinished,
    currentBreakAnimation,
  } = useActiveBoostersItem({
    timeEnd: booster.timeEnd,
    timeStart: booster.timeStart,
    Div,
    booster,
  })

  const isBoosterBreaking = percentageProgress < 10

  const getAnimationTime = () => {
    if (percentageProgress > 7) return 2

    if (percentageProgress > 5) return 1

    if (percentageProgress > 3) return 0.5

    return 0.25
  }

  const { classes: classesStyle, cx } = useStyles({
    popoverItem,
    boosterType: getBoosterPercentageColor(),
    boosterColors: boosterData.colors,
    isNew: booster.isNew,
    animationTime: getAnimationTime(),
    animationBoosterFinished,
  })

  const isOpen =
    !closedVersion && (popoverItem || currentBooster === booster._id)

  const [newBoosterAnimationData, setNewBoosterAnimationData] =
    useState<GeneralModels.LottieAnimationData | null>(null)

  const [explosionBoosterAnimationData, setExplosionBoosterAnimationData] =
    useState<GeneralModels.LottieAnimationData | null>(null)

  useEffect(() => {
    getLottieAnimationData(
      getAssetsUrl(
        baseUrl,
        'assets/animations/booster/booster-activation.lottie',
      ),
      setNewBoosterAnimationData,
    )

    getLottieAnimationData(
      getAssetsUrl(
        baseUrl,
        'assets/animations/booster/booster-breaking-explosion.lottie',
      ),
      setExplosionBoosterAnimationData,
    )
  }, []) // eslint-disable-line

  return (
    <>
      {timeRemaining === null && popoverItem && (
        <Skeleton
          className={classesStyle.skeleton}
          key={`skeleton-${booster._id}`}
        />
      )}
      {progress !== 0 && (
        <Box
          className={`${cx({
            [classesStyle.container]: true,
            [classesStyle.isOpen]: isOpen,
            [classesStyle.popoverItem]: popoverItem,
          })}`}
          sx={{
            order: index || 'inherit',
          }}
        >
          <Box className={classesStyle.wrapper}>
            {booster.isNew ? (
              <NewActiveBoosterItemComponent
                key={`active-booster-new-${booster._id}`}
                booster={booster}
                newBoosterAnimationData={newBoosterAnimationData}
                index={index}
              />
            ) : (
              <Box
                className={classesStyle.box}
                onClick={() =>
                  !popoverItem &&
                  !closedVersion &&
                  handleCurrentBooster(booster._id)
                }
                sx={{
                  cursor: closedVersion || popoverItem ? 'initial' : 'pointer',
                }}
              >
                {animationBoosterFinished ? (
                  <Box
                    sx={{
                      width: 42,
                      position: 'relative',
                      order: index || 'inherit',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        width: 80,
                        left: -20,
                        top: -20,
                        zIndex: 99,
                      }}
                    >
                      <Lottie
                        animationData={explosionBoosterAnimationData}
                        loop={false}
                      />
                    </Box>
                  </Box>
                ) : (
                  <CircularProgressComponent
                    percentageProgress={percentageProgress}
                    boosterRate={booster.boosterRate}
                    image={boosterData.image}
                    breakingAnimation={
                      isBoosterBreaking && (
                        <BreakingBoosterAnimationComponent
                          currentBreakAnimation={currentBreakAnimation}
                          imageClass={classesStyle.zoomImage}
                        />
                      )
                    }
                    boosterType={getBoosterPercentageColor()}
                    boosterColors={boosterData.colors}
                  />
                )}
              </Box>
            )}

            <Box className={classesStyle.content}>
              <Box className={classesStyle.contentText}>
                <Typography fontWeight={500} variant="caption" lineHeight={1.5}>
                  {boosterData.title}
                </Typography>
                <Box display="flex" gap="5px" alignItems="center">
                  <AccessTimeOutlined className={classesStyle.clockIcon} />
                  <Typography fontWeight={700} variant="caption">
                    {timeRemaining}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
