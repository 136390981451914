import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { AccessTime, ArrowBack, InfoOutlined } from '@mui/icons-material'

import {
  MissionTabsEnum,
  SidebarMenuItemType,
  UIDialogsEnum,
  VariantsComponentsEnum,
} from '@/enums'
import {
  CarouselDirectionArrowsComponent,
  HeaderNavigationComponent,
  MissionBundleDetailComponent,
  NoContentIconComponent,
} from '@/components/common'
import { useBreakpoints, useGlobalUi } from '@/hooks'

import { useMissionStyles } from '../style'
import {
  ActiveBundleStandardComponent,
  MissionBundlesStandardComponent,
  MissionBundleInfoComponent,
} from './'
import { useMissionsStandard, useMissionsStandardScroll } from '../hooks'

type Props = {
  isMissionWidget?: boolean
  handleSetFullContent?: (full: boolean) => void
  handleChangeMenuItem?: (sidebarMenuItem: string) => void
}

export const MissionStandardComponent = ({
  isMissionWidget,
  handleSetFullContent,
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const { isMobile, isTablet } = useBreakpoints()

  const { classes } = useMissionStyles()

  const {
    contentRef,
    missionActiveBundles,
    missionExpiredBundles,
    missionBundles,
    selectedBundle,
    missionTab,
    isLoading,
    selectedBundleName,
    selectedBundleDescription,
    selectedBundleTermsAndConditions,
    handleSetSelectedBundle,
    setSelectedBundle,
    setMissionTab,
  } = useMissionsStandard()

  const { handleOpenDialog } = useGlobalUi()

  const { showLeftArrow, showRightArrow, handleNext, handleBack } =
    useMissionsStandardScroll({ contentRef })

  const handleGoBackDetails = () => {
    setSelectedBundle(null)
    !!handleSetFullContent && handleSetFullContent(false)
  }

  const handleGoBackBundle = () => {
    return missionTab === MissionTabsEnum.MISSIONS
      ? handleChangeMenuItem(SidebarMenuItemType.HOME)
      : setMissionTab(MissionTabsEnum.MISSIONS)
  }

  const handleBackBundle =
    (!isMissionWidget || missionTab === MissionTabsEnum.HISTORY) &&
    handleGoBackBundle

  const goBackButton = isMissionWidget &&
    !isMobile &&
    (missionTab === MissionTabsEnum.HISTORY || selectedBundle) && (
      <IconButton
        sx={{
          fontSize: 16,
          color: 'inherit',
          borderRadius: 2,
          border: `1px solid ${theme.palette.text.secondary}`,
          display: 'flex',
          gap: 0.5,
        }}
        size="small"
        onClick={selectedBundle ? handleGoBackDetails : handleBackBundle}
      >
        <ArrowBack fontSize="inherit" />
        {selectedBundle && (
          <Typography fontWeight={600} fontSize={14}>
            {t('goBack').toUpperCase()}
          </Typography>
        )}
      </IconButton>
    )

  return (
    <>
      <Box
        className={classes.missionWrapper}
        mt={selectedBundle ? '20px' : 0}
        borderRadius={!isMissionWidget ? '8px 8px 0 0' : '0'}
        position="relative"
      >
        {selectedBundle ? (
          <>
            <Box
              bgcolor={!isMissionWidget && theme.palette.primary.main}
              color={!isMissionWidget && theme.palette.common.white}
              borderRadius={!isMissionWidget ? '8px 8px 0 0' : '0'}
              padding="10px 10px 25px"
            >
              <HeaderNavigationComponent
                title={(!isMissionWidget || isMobile) && selectedBundleName}
                customGoBackButton={goBackButton}
                goBack={handleGoBackDetails}
                interaction={
                  (selectedBundleDescription !== '' ||
                    selectedBundleTermsAndConditions !== '') &&
                  !(isMissionWidget && !isMobile && !isTablet) ? (
                    <IconButton
                      onClick={() =>
                        handleOpenDialog({
                          id: isMissionWidget
                            ? UIDialogsEnum.MISSION_BUNDLE_PAGE_INFO
                            : UIDialogsEnum.MISSION_BUNDLE_INFO,
                        })
                      }
                      sx={{
                        color: !isMissionWidget && theme.palette.common.white,
                      }}
                    >
                      <InfoOutlined sx={{ fontSize: 20 }} />
                    </IconButton>
                  ) : undefined
                }
              />
            </Box>

            <MissionBundleDetailComponent
              missionBundle={selectedBundle}
              variant={
                isMissionWidget && !isMobile
                  ? VariantsComponentsEnum.LARGE
                  : VariantsComponentsEnum.SMALL
              }
              showEndedMissions={missionTab === MissionTabsEnum.HISTORY}
              isMissionWidget={isMissionWidget}
              selectedBundleName={selectedBundleName}
              selectedBundleDescription={selectedBundleDescription}
            />
          </>
        ) : (
          <Box px={!isMissionWidget && 2} pt={2}>
            <HeaderNavigationComponent
              title={
                missionTab === MissionTabsEnum.MISSIONS
                  ? t('missions')
                  : t('missionsHistory')
              }
              titleSxProps={{ textTransform: 'uppercase' }}
              customGoBackButton={goBackButton}
              goBack={handleBackBundle}
              interaction={
                <>
                  {missionTab === MissionTabsEnum.MISSIONS && (
                    <Button
                      startIcon={<AccessTime />}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setMissionTab(MissionTabsEnum.HISTORY)
                      }}
                      className={classes.historyButton}
                    >
                      {isMissionWidget && !isMobile
                        ? t('missionHistory')
                        : t('history')}
                    </Button>
                  )}
                  {missionTab === MissionTabsEnum.HISTORY &&
                    missionExpiredBundles?.length > 0 && (
                      <Chip
                        label={t('lastNumberDays', { number: 30 })}
                        icon={<AccessTime sx={{ fontSize: 16 }} />}
                        sx={{
                          background: 'none',
                          fontWeight: 600,
                          '& .GamanzaChip-label': {
                            paddingRight: 0,
                          },
                        }}
                      />
                    )}
                </>
              }
            />
          </Box>
        )}

        <Box position="relative" width="100%" paddingY={isMissionWidget && 2}>
          {isMissionWidget && !isMobile && selectedBundle?.missionsInOrder && (
            <CarouselDirectionArrowsComponent
              showArrowLeft={showLeftArrow}
              showArrowRight={showRightArrow}
              handleNext={handleNext}
              handleBack={handleBack}
              topPosition="37.5%"
              arrowType="arrow"
            />
          )}

          <Box
            className={classes.missionContent}
            mt={2}
            padding={2}
            paddingY={isMissionWidget && 2}
            ref={contentRef}
            bgcolor={selectedBundle && theme.palette.background.paper}
            borderRadius={selectedBundle && 4}
          >
            <>
              {isLoading ||
              (missionTab === MissionTabsEnum.MISSIONS &&
                missionActiveBundles === null) ||
              (missionTab === MissionTabsEnum.HISTORY &&
                missionExpiredBundles === null) ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  minHeight={300}
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {(missionTab === MissionTabsEnum.MISSIONS &&
                    missionActiveBundles?.length > 0) ||
                  (missionTab === MissionTabsEnum.HISTORY &&
                    missionExpiredBundles?.length > 0) ? (
                    <>
                      {selectedBundle ? (
                        <ActiveBundleStandardComponent
                          missionBundle={selectedBundle}
                          componentRef={contentRef}
                          showEndedMissions={
                            missionTab === MissionTabsEnum.HISTORY
                          }
                          isMissionWidget={isMissionWidget}
                        />
                      ) : (
                        <MissionBundlesStandardComponent
                          missionBundles={missionBundles}
                          showEndedMissions={
                            missionTab === MissionTabsEnum.HISTORY
                          }
                          isMissionWidget={isMissionWidget}
                          handleSetFullContent={handleSetFullContent}
                          handleSetSelectedBundle={handleSetSelectedBundle}
                        />
                      )}
                    </>
                  ) : (
                    <Box minHeight={400} display="flex" alignItems="center">
                      <NoContentIconComponent
                        title={t('noMissions')}
                        message={t('noMissionsMessage')}
                        buttonAction={() => {
                          handleChangeMenuItem(SidebarMenuItemType.HOME)
                        }}
                        buttonText={!isMissionWidget && t('goHome')}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          </Box>
        </Box>
      </Box>
      <MissionBundleInfoComponent
        selectedBundleDescription={selectedBundleDescription}
        selectedBundleName={selectedBundleName}
        selectedBundleTermsAndConditions={selectedBundleTermsAndConditions}
        isMissionWidget={isMissionWidget}
      />
    </>
  )
}
