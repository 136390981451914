import { missions } from './missions.translation'
import { optin } from './optin.translation'
import { rewardShop } from './reward-shop.translation'

import { time } from './time.translation'
import { translation } from './widgets.translation'

export const en = {
  translation: {
    ...missions,
    ...optin,
    ...rewardShop,
    ...time,
    ...translation,
  },
}
