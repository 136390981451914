import { Theme, alpha } from '@mui/material'

export const getBorderColorByMode = (
  theme: Theme,
  light?: number,
  dark?: number,
) => {
  return theme.palette.mode === 'dark'
    ? alpha(theme.palette.common.white, dark ?? 0.08)
    : alpha(theme.palette.common.black, light ?? 0.05)
}

export const animationFadeIn = {
  animation: 'flexAnimationOpacity 0.35s ease-in forwards',

  '@keyframes flexAnimationOpacity': {
    '0%': {
      opacity: 0,
    },

    '100%': {
      opacity: 1,
    },
  },
}

export const wysiwygTextProps = {
  '& p, & ul, & ol': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    wordWrap: 'break-word' as 'break-word',
  },
  '& ul, & ol': {
    paddingLeft: '20px',
  },
  '& ul': {
    listStyleType: 'disc',
    '& li::before': {
      content: 'none',
    },
  },
}
