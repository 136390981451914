import { typographyClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useMissionStyles = makeStyles()((theme) => {
  return {
    missionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      animation: 'flexAnimationOpacity 0.35s ease-in forwards',
      height: '100%',

      '@keyframes flexAnimationOpacity': {
        '0%': {
          opacity: 0,
        },

        '100%': {
          opacity: 1,
        },
      },
    },

    missionContent: {
      width: '100%',
    },

    bundleContent: {
      '& .bundleHeader': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,

        [`& .${typographyClasses.body1}`]: {
          fontSize: 16,
          fontWeight: 600,
        },

        '& .remaining': {
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          fontSize: 12,
          fontWeight: 600,
          textTransform: 'uppercase',
          padding: '4px 6px',

          '& svg': {
            fontSize: 15,
          },
        },
      },
    },

    listMissions: {
      display: 'flex',
      gap: 24,
      justifyContent: 'center',
      flexWrap: 'wrap',

      '& .titleMissionAnyOrder': {
        fontWeight: 600,
        width: '100%',
        marginBottom: 10,
      },
    },

    listMissionsHistory: {
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
      justifyContent: 'left',
      flexWrap: 'wrap',
    },

    historyButton: {
      color: theme.palette.text.secondary,
      borderColor: theme.palette.text.secondary,

      '&:hover': {
        borderColor: theme.palette.text.secondary,
      },
    },
  }
})
