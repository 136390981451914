import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { ChestAnimationStatusEnum, ChestTypeEnum } from '@/enums'

import { useGlobalUi } from '@/hooks'
import { useRewardActions } from './use-reward-actions.hook'

export const useRewardAnimation = () => {
  const { dialog } = useGlobalUi()

  const [animationStatus, setAnimationStatus] = useState('')
  const [showScroll, setShowScroll] = useState(false)
  const objectData = dialog.object

  const animationStatusRef = useRef(animationStatus)

  const {
    claimMissionRewards,
    claimPlayerRewards,
    removeChestByGroupId,
    removeChestByMissionId,
  } = useRewardActions()

  const rewardCardRef = useRef()
  const tlRewardCard = gsap.timeline()

  const elementsOneIcon = document.querySelectorAll('.rewardItemOneIcon')
  const tlRewardsOneIcon = gsap.timeline()

  const elementsOneText = document.querySelectorAll('.rewardItemOneText')
  const tlRewardsOneText = gsap.timeline()

  const elementsList = document.querySelectorAll('.rewardItemList')
  const tlRewardsList = gsap.timeline()

  const animateOneByListElement = () => {
    elementsList.forEach((element) => {
      tlRewardsList.fromTo(
        element,
        {
          opacity: 0,
          scale: 0,
        },
        {
          opacity: 1,
          duration: 0.4,
          scale: 1,
        },
      )
    })

    tlRewardsList.play()
    setShowScroll(true)
  }

  const animateOneByOneElement = () => {
    elementsOneIcon.forEach((element) => {
      tlRewardsOneIcon.fromTo(
        element,
        {
          opacity: 0,
          scale: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          scale: 1.4,
        },
      )
      // Bounce effect
      tlRewardsOneIcon.to(element, {
        duration: 0.1,
        scale: 1,
      })
      tlRewardsOneIcon.to(element, {
        duration: 0.1,
        scale: 1.2,
      })
      tlRewardsOneIcon.to(element, {
        duration: 0.1,
        scale: 1,
      })
      tlRewardsOneIcon.to(element, {
        opacity: 0,
        duration: 0.2,
        scale: 0,
        delay: 1.5,
      })
    })

    elementsOneText.forEach((element) => {
      tlRewardsOneText.fromTo(
        element,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.8,
        },
      )
      tlRewardsOneText.to(element, {
        opacity: 0,
        duration: 0.2,
        delay: 1.5,
      })
    })

    tlRewardsOneIcon.play()
    tlRewardsOneText.play()

    const timeout = 2500 * elementsOneIcon.length

    setTimeout(() => {
      if (animationStatusRef.current === ChestAnimationStatusEnum.SHOWING) {
        animateOneByListElement()
        setAnimationStatus(ChestAnimationStatusEnum.OPENED)
      }
    }, timeout)
  }

  const triggerListRewardsAnimations = () => {
    tlRewardCard.fromTo(
      rewardCardRef.current,
      {
        opacity: 0,
        scale: 0.1,
      },
      {
        delay: 1.9,
        duration: 0.7,
        opacity: 1,
        scale: 1,
      },
    )
  }

  const handleInitAnimation = () => {
    setTimeout(() => {
      setAnimationStatus(ChestAnimationStatusEnum.APPEARS)
    }, 500)

    setTimeout(() => {
      setAnimationStatus(ChestAnimationStatusEnum.JUMPING)
    }, 1000)
  }

  const handleOpeningAnimation = () => {
    setAnimationStatus(ChestAnimationStatusEnum.OPENING)
    claimMissionRewardsAction()
    triggerListRewardsAnimations()

    setTimeout(() => {
      if (animationStatusRef.current === ChestAnimationStatusEnum.OPENING) {
        setAnimationStatus(ChestAnimationStatusEnum.SHOWING)
        setTimeout(() => {
          if (animationStatusRef.current === ChestAnimationStatusEnum.SHOWING) {
            animateOneByOneElement()
          }
        }, 700)
      }
    }, 2000)
  }

  const handleOpenedAnimation = () => {
    setAnimationStatus(ChestAnimationStatusEnum.OPENED)
    animateOneByListElement()
  }

  const claimMissionRewardsAction = () => {
    if (objectData?.chestType === ChestTypeEnum.MISSION) {
      claimMissionRewards({
        missionStateId: objectData?.missionId,
        bundleStateId: objectData?.bundleId,
      })
      if (objectData.groupId) {
        removeChestByGroupId({
          groupId: objectData.groupId || objectData?.missionId,
          chestType: dialog.object?.chestType,
        })
      } else {
        removeChestByMissionId({
          missionId: objectData?.missionId,
          chestType: dialog.object?.chestType,
        })
      }
    } else {
      claimPlayerRewards({ groupId: objectData.groupId })
      removeChestByGroupId({
        groupId: objectData.groupId,
        chestType: dialog.object?.chestType,
      })
    }
  }

  useEffect(() => {
    animationStatusRef.current = animationStatus
  }, [animationStatus])

  useEffect(() => {
    handleInitAnimation()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!dialog.open) {
      tlRewardsOneIcon.kill()
      tlRewardsOneText.kill()
    }
  }, [dialog]) // eslint-disable-line

  return {
    animationStatus,
    rewardCardRef,
    showScroll,
    handleOpeningAnimation,
    handleOpenedAnimation,
  }
}
