import { Chip } from '@mui/material'
import { green, red, grey } from '@mui/material/colors'
import { Lock } from '@mui/icons-material'

import { NameIdType } from '@/state/models'

type Props = {
  items: NameIdType[]
  type: 'ranks' | 'tags' | 'extra'
  size: 'small' | 'medium'
  margins?: string
}

const colorMap = {
  ranks: {
    color: red[800],
    bgColor: red[50],
    borderColor: red[800],
  },
  tags: {
    color: green[600],
    bgColor: green[50],
    borderColor: green[800],
  },
  extra: {
    color: grey[800],
    bgColor: grey[50],
    borderColor: grey[400],
  },
}

export const ImageChips = ({ items, type, size, margins }: Props) => {
  return (
    <>
      {items.map((item: NameIdType) => {
        return (
          <Chip
            key={item.name}
            variant="outlined"
            size={size}
            label={item.name}
            icon={
              type === 'ranks' ? (
                <Lock sx={{ color: `${colorMap[type].color}!important` }} />
              ) : undefined
            }
            sx={{
              fontSize: size === 'small' ? '10px' : '13px',
              margin: margins,
              textTransform: 'capitalize',
              fontWeight: 700,
              color: colorMap[type].color,
              backgroundColor: colorMap[type].bgColor,
              border: `1px solid ${colorMap[type].borderColor}`,
              borderRadius: '6px',
            }}
          />
        )
      })}
    </>
  )
}
