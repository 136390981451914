import { makeStyles } from 'tss-react/mui'

interface Props {
  client?: string
}

export const useRewardShopStyles = makeStyles<Props>()((theme, { client }) => {
  return {
    menuContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuItem: {
      padding: '4px 12px',
      marginRight: 4,
      whiteSpace: 'nowrap',
    },
    navigationButtonRight: {
      width: 30,
      height: 30,
      padding: 0,
      marginLeft: 4,
    },
    navigationButtonLeft: {
      width: 30,
      height: 30,
      padding: 0,
      marginRight: 4,
    },
    shopContent: {
      padding: 16,
    },
    menuItemsContainer: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      width: 240,
    },
    menuItemsScrollContainer: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
      alignItems: 'center',
      marginTop: 2,
      paddingBottom: 2,
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }
})
