import { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { Box, darken, useTheme } from '@mui/material'

import { RewardShopModels, RootState } from '@/state'
import { useBreakpoints } from '@/hooks'
import {
  ImageChips,
  CarouselDirectionArrowsComponent,
  SliderBulletsComponent,
  useProductImages,
} from '../'
import { getExpirationText } from '@/utils'

type Props = {
  product: RewardShopModels.ProductType
}

export const ProductImagesComponent = ({ product }: Props) => {
  const theme = useTheme()

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { isTablet } = useBreakpoints()

  const {
    isMobileOrTablet,
    scrollContainerRef,
    currentPreviewIndex,
    productImages,
    setCurrentPreviewIndex,
    handleArrowClick,
    handleBulletClick,
    handleScroll,
    extractFileName,
  } = useProductImages({ product })

  const hasMultipleImages = productImages.length > 1

  const commonStylesImage: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  }

  const getProductImage = (image: string) => {
    return (
      <Box
        key={image}
        width="100%"
        height="100%"
        flexShrink={0}
        sx={{
          scrollSnapAlign: 'start',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          overflow="hidden"
          position="relative"
          paddingTop="66.67%"
        >
          <img
            src={image}
            alt={extractFileName(image)}
            style={commonStylesImage}
          />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        borderRadius={4}
        bgcolor={darken(theme.palette.background.paper, 0.05)}
        border={`solid 1px ${darken(theme.palette.background.paper, 0.1)}`}
        maxWidth={isTablet ? 500 : '100%'}
        margin="0 auto"
      >
        {isMobileOrTablet ? (
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            height="100%"
            alignItems="center"
            ref={scrollContainerRef}
            onScroll={handleScroll}
            sx={{
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {productImages?.map((image: string) => getProductImage(image))}
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            position="relative"
            paddingTop="66.67%"
          >
            <img
              src={productImages[currentPreviewIndex]}
              alt={extractFileName(productImages[currentPreviewIndex])}
              style={commonStylesImage}
            />
          </Box>
        )}

        <Box position="absolute" top={10} left={10}>
          {product?.ranks?.length > 0 && (
            <ImageChips
              items={product?.ranks?.map((item) => ({
                name:
                  item?.translations?.find(
                    (trans) =>
                      trans.language.toUpperCase() ===
                      playerLocale.toUpperCase(),
                  )?.name ||
                  item?.translations?.[0]?.name ||
                  item?.name,
                id: item._id,
              }))}
              type="ranks"
              size={isMobileOrTablet ? 'small' : 'medium'}
              margins="0 4px 4px 0"
            />
          )}
          {product?.tags?.length > 0 && (
            <ImageChips
              items={product?.tags}
              type="tags"
              size={isMobileOrTablet ? 'small' : 'medium'}
              margins="0 4px 4px 0"
            />
          )}
          {product.reward?.booster?.expirationValidityTime && (
            <ImageChips
              items={[
                {
                  id: 'expiration',
                  name: getExpirationText(product.reward?.booster).join(''),
                },
              ]}
              type="extra"
              size="small"
            />
          )}
        </Box>
        {hasMultipleImages && isMobileOrTablet && (
          <>
            <CarouselDirectionArrowsComponent
              showArrowLeft={currentPreviewIndex !== 0}
              showArrowRight={currentPreviewIndex !== productImages.length - 1}
              arrowType="button"
              sxProps={{
                right: {
                  '&.right': {
                    right: 5,
                  },
                },
                left: {
                  '&.left': {
                    left: 5,
                  },
                },
              }}
              handleNext={() => handleArrowClick('right')}
              handleBack={() => handleArrowClick('left')}
            />
            <SliderBulletsComponent
              currentBullet={currentPreviewIndex}
              bulletsCount={productImages?.length}
              handleScroll={(index: number) => handleBulletClick(index)}
            />
          </>
        )}
      </Box>

      {hasMultipleImages && !isMobileOrTablet && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          p={1}
          mb={1}
          gap={1.25}
          flexWrap="wrap"
        >
          {productImages.map((image, index) => (
            <Box
              key={`image-${image}`}
              onClick={() =>
                isMobileOrTablet
                  ? handleBulletClick(index)
                  : setCurrentPreviewIndex(index)
              }
              display="flex"
              width={70}
              borderRadius={2}
              flexShrink={0}
              overflow="hidden"
              border={`solid 1px ${theme.palette.grey[300]}`}
              alignItems="center"
              justifyContent="center"
            >
              <Box
                width="100%"
                height="100%"
                position="relative"
                paddingTop="66.67%"
                sx={{
                  cursor: 'pointer',
                  opacity: currentPreviewIndex === index ? 1 : 0.7,
                }}
              >
                <img
                  src={image}
                  alt={extractFileName(image)}
                  width="auto"
                  height="auto"
                  style={commonStylesImage}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}
