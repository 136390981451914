import { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { MissionCommonModels } from '@/state'
import { useBreakpoints, useGlobalUi } from '@/hooks'
import {
  DialogTypeEnum,
  OrientationComponentsEnum,
  UIDialogsEnum,
  VariantsComponentsEnum,
} from '@/enums'

import {
  MissionItemStandardComponent,
  TimelineMissionsComponent,
} from '@/components/common'
import { useMissionStyles } from '../style'

type Props = {
  missionBundle: MissionCommonModels.MissionHistoryBundleDetailsType
  componentRef: MutableRefObject<HTMLDivElement>
  showEndedMissions: boolean
  isMissionWidget: boolean
}

export const ActiveBundleStandardComponent = ({
  missionBundle,
  componentRef,
  showEndedMissions,
  isMissionWidget,
}: Props) => {
  const { t } = useTranslation()

  const { isMobile } = useBreakpoints()

  const { handleOpenDialog } = useGlobalUi()

  const { classes } = useMissionStyles()

  return (
    <Box className={classes.listMissions}>
      {missionBundle.missionsInOrder ? (
        <TimelineMissionsComponent
          missions={missionBundle.missionIds}
          handleClick={(id: string) => {
            handleOpenDialog({
              id: UIDialogsEnum.MISSION_DETAILS,
              object: {
                missionId: id,
                title: t(
                  `timeframeTypeMissions.${missionBundle.settings.timeFrame.timeFrameType}`,
                ),
                type: DialogTypeEnum.MISSIONS,
                showEndedMissions: showEndedMissions,
              },
            })
          }}
          status={missionBundle.status}
          state={missionBundle.state}
          timelineType={
            isMissionWidget && !isMobile
              ? OrientationComponentsEnum.HORIZONTAL
              : OrientationComponentsEnum.VERTICAL
          }
          componentRef={componentRef}
        />
      ) : (
        <>
          <Typography
            className="titleMissionAnyOrder"
            variant="body2"
            fontSize={isMissionWidget ? 20 : 14}
          >
            {t(
              `timeframeTypeMissions.${missionBundle.settings.timeFrame.timeFrameType}`,
            )}
          </Typography>
          {missionBundle.missionIds.map((mission) => {
            return (
              <MissionItemStandardComponent
                status={mission.status}
                state={mission.state}
                endDate={mission.endDate}
                orientation={OrientationComponentsEnum.VERTICAL}
                variant={
                  isMissionWidget
                    ? VariantsComponentsEnum.LARGE
                    : VariantsComponentsEnum.SMALL
                }
                handleClick={() => {
                  handleOpenDialog({
                    id: UIDialogsEnum.MISSION_DETAILS,
                    object: {
                      missionId: mission._id,
                      title: t(
                        `timeframeTypeMissions.${missionBundle.settings.timeFrame.timeFrameType}`,
                      ),
                      type: DialogTypeEnum.MISSIONS,
                      showEndedMissions: showEndedMissions,
                    },
                  })
                }}
                key={`mission_${mission._id}`}
              />
            )
          })}
        </>
      )}
    </Box>
  )
}
