import { alpha, GlobalStylesProps, Theme } from '@mui/material'

export const globalStyles = (theme: Theme): GlobalStylesProps => {
  const thumbColor = alpha(theme.palette.text.primary, 0.4)
  const trackColor = alpha(theme.palette.text.primary, 0.1)

  return {
    styles: {
      '.GamanzaScopedCssBaseline-root, .Gamanzaglobal_ui_components, .GamanzaDialog-root, .GamanzaModal-root, .GamanzaTooltip-popper':
        {
          '*': {
            boxSizing: 'border-box',
            '& .paintIcon': {
              '& g path': {
                fill: theme.palette.primary.main,
              },
            },
          },
          /* WebKit Scrollbar */
          '*::-webkit-scrollbar': {
            WebkitAppearance: 'none',
          },
          '*::-webkit-scrollbar:vertical': {
            width: 4,
          },
          '*::-webkit-scrollbar:horizontal': {
            height: 4,
          },
          '*::-webkit-scrollbar-thumb': {
            background: thumbColor,
            borderRadius: 16,
            opacity: '1',
          },
          '*::-webkit-scrollbar-track': {
            background: trackColor,
            borderRadius: 16,
            opacity: '0.3',
          },
          '@-moz-document url-prefix()': {
            '*': {
              scrollbarWidth: 'thin',
              scrollbarColor: `${thumbColor} transparent`,
            },
          },
        },
    },
  }
}
