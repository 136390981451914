import { useState } from 'react'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import { RootState } from '@/state'
import { ImageChips } from '@/components'

import { ImageDefault } from '@/constants'
import { CardItemContentType } from '@/state/models'
import { useStyles } from './style'

type Props = {
  item: CardItemContentType
  contentHeight?: number
  cardContentComponent?: React.ReactNode
  cardActionsComponent?: React.ReactNode
  tooltip?: React.ReactNode
  imageHeight?: number
  contentChip?: string
  onClick?: () => void
}

export const CardItemComponent = ({
  item,
  contentHeight,
  cardContentComponent,
  cardActionsComponent,
  tooltip,
  contentChip,
  onClick,
}: Props) => {
  const { title, description, image, imageHover } = item

  const [currentImage, setCurrentImage] = useState(image || ImageDefault)

  const { client } = useSelector(({ initConfig }: RootState) => initConfig)

  const { gamificationOpt } = useSelector(({ player }: RootState) => player)

  const { classes } = useStyles({ client })

  const handleMouseEnter = () => {
    if (imageHover) {
      setCurrentImage(imageHover)
    }
  }

  const handleMouseLeave = () => {
    setCurrentImage(image || ImageDefault)
  }

  const CardArea = (
    <Box position="relative">
      <Box position="relative">
        <Box className={classes.imageContainer}>
          <CardMedia
            component="img"
            image={currentImage}
            className={classes.cardMedia}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </Box>

        {(item?.ranks?.length > 0 ||
          item?.tags?.length > 0 ||
          contentChip?.length > 0) && (
          <Box className={classes.imageChips}>
            {item?.ranks?.length > 0 && (
              <ImageChips
                margins="0 2px 2px 0"
                items={item?.ranks}
                type="ranks"
                size="small"
              />
            )}

            {item?.tags?.length > 0 && (
              <ImageChips
                margins="0 2px 2px 0"
                items={item?.tags}
                type="tags"
                size="small"
              />
            )}

            {contentChip && (
              <ImageChips
                items={[
                  {
                    id: 'expiration',
                    name: contentChip,
                  },
                ]}
                type="extra"
                size="small"
              />
            )}
          </Box>
        )}
      </Box>
      <CardContent
        sx={{ height: contentHeight ?? 100 }}
        className={classes.cardContent}
      >
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.title}>{title}</Typography>

          {tooltip}
        </Box>
        <Typography
          variant="body2"
          component="div"
          color="text.secondary"
          className={classes.description}
        >
          {description && parse(description)}
        </Typography>
        {cardContentComponent}
      </CardContent>
    </Box>
  )

  return (
    <Card className={classes.card}>
      {onClick && gamificationOpt.enable ? (
        <CardActionArea onClick={onClick}>{CardArea}</CardActionArea>
      ) : (
        CardArea
      )}

      {cardActionsComponent && (
        <CardActions>{cardActionsComponent}</CardActions>
      )}
    </Card>
  )
}
