import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  alpha,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'

import { RootState } from '@/state'
import { SidebarMenuItemType } from '@/enums'
import { ReactComponent as IconSidebarShop } from '@/assets/icons/icon-sidebar-shop.svg'

import { ReactComponent as IconSidebarHome } from '@/assets/icons/icon-sidebar-home.svg'
import { ReactComponent as IconSidebarMissions } from '@/assets/icons/icon-sidebar-missions.svg'
import { ReactComponent as IconSidebarTournaments } from '@/assets/icons/icon-sidebar-missions.svg'

import { animationFadeIn } from '@/constants'

type Props = {
  sidebarMenuItem: SidebarMenuItemType
  handleChangeMenuItem: (item: SidebarMenuItemType) => void
}

export const SidebarMenuComponent = ({
  sidebarMenuItem,
  handleChangeMenuItem,
}: Props) => {
  const { t } = useTranslation()

  const { missionsEnable } = useSelector(
    ({ globalSettings }: RootState) => globalSettings,
  )

  const { text, primary, mode, common } = useTheme().palette

  const menuOptions = [
    {
      id: SidebarMenuItemType.HOME,
      label: 'home',
      icon: <IconSidebarHome />,
      isVisible: true,
    },
    {
      id: SidebarMenuItemType.REWARD_SHOP,
      label: 'shop',
      icon: <IconSidebarShop />,
      isVisible: true,
    },
    {
      id: SidebarMenuItemType.TOURNAMENTS,
      label: 'tournaments',
      icon: <IconSidebarTournaments />,
      isVisible: false,
    },
    {
      id: SidebarMenuItemType.MISSIONS,
      label: 'missions',
      icon: <IconSidebarMissions />,
      isVisible: missionsEnable,
    },
  ]

  return (
    <Paper
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0,
        ...animationFadeIn,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={sidebarMenuItem}
        onChange={(_, newValue) => handleChangeMenuItem(newValue)}
        sx={{
          p: 0.3,
          gap: 0.3,
        }}
      >
        {menuOptions
          .filter((item) => item.isVisible)
          .map((item) => {
            return (
              <BottomNavigationAction
                showLabel={false}
                value={item.id}
                key={item.label}
                label={
                  <Typography fontSize={10} fontWeight={900}>
                    {t(`${item.label}`).toUpperCase()}
                  </Typography>
                }
                icon={item.icon}
                sx={{
                  border: `1px solid ${alpha(text.primary, 0.1)}`,
                  borderRadius: 0.3,
                  color: primary.main,
                  minWidth: 0,
                  '& svg path': {
                    fill: mode === 'dark' ? text.primary : primary.main,
                  },
                  '&.Mui-selected': {
                    flex: '1 0 auto',
                    maxWidth: 110,
                    background: primary.main,
                    color: common.white,
                    '& svg path': {
                      fill: common.white,
                    },
                  },
                }}
              />
            )
          })}
      </BottomNavigation>
    </Paper>
  )
}
