import { makeStyles } from 'tss-react/mui'
import { typographyClasses, buttonBaseClasses } from '@mui/material'

interface Props {
  client: string
}

export const useStyles = makeStyles<Props>()((theme, { client }) => {
  return {
    products: {
      paddingTop: '20px',
      paddingBottom: '20px',
      position: 'relative',

      '& .categoryContent': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '15px',
        paddingRight: client === 'JP' ? '60px' : 0,
        paddingLeft: client === 'JP' ? '70px' : 0,

        [theme.breakpoints.down('sm')]: {
          paddingRight: '0px',
          paddingLeft: '0px',
        },

        '&.fullList': {
          paddingRight: '0px',
          paddingLeft: '0px',

          [`& .${buttonBaseClasses.root}`]: {
            padding: '0px 5px',
          },
        },

        [`& .${typographyClasses.h6}`]:
          client === 'JP'
            ? {
                textTransform: 'uppercase',
                fontWeight: 900,
                fontSize: '28px',
                fontStyle: 'italic',

                [theme.breakpoints.down('sm')]: {
                  fontSize: '20px',
                },
              }
            : {
                textTransform: 'capitalize',
                fontWeight: 700,
                fontSize: '16px',
              },

        [`& .${buttonBaseClasses.root}`]:
          client === 'JP'
            ? {
                color: theme.palette.primary.main,
                textDecoration: 'underline',
                textTransform: 'uppercase',
                fontSize: '14px',
                fontWeight: 600,
                padding: 0,
              }
            : {
                color: theme.palette.text.secondary,
                padding: 0,
              },
      },
    },

    cardItem: {
      width: '100%',
    },

    carouselSnap: {
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      scrollBehavior: 'smooth',
      paddingBottom: 16,
      width: client === 'JP' ? 'calc(100% - 120px)' : 'inherit',
      margin: client === 'JP' ? '0px auto' : 'initial',
      marginBottom: 16,

      '& > *': {
        scrollSnapAlign: 'start',
      },

      [theme.breakpoints.down('sm')]: {
        width: client === 'JP' ? 'calc(100% + 32px)' : 'inherit',
      },
    },
    cardArrow: {
      position: 'absolute',
      marginTop: '10px',
      width: '90px',
      backgroundColor: 'transparent !important',
      height: '307px',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      padding: '0 0 0 15px',

      '&.left': {
        left: '-30px',
        transform: 'rotate(180deg)',
      },

      '&.right': {
        right: '-30px',
      },

      '& svg': {
        fontSize: '40px',
      },
    },
  }
})
