import { WindowHashID } from '@/constants'

export const resetPIDWindowHash = () => {
  const windowHash = window.location.hash
  if (
    windowHash !== '' &&
    windowHash.slice(0, 5).trim() === WindowHashID.toString()
  ) {
    window.location.hash = ''
    window.history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search,
    )
  }
}

export const getPIDWindowHash = (): string => {
  const windowHash = window.location.hash
  if (
    windowHash !== '' &&
    windowHash.slice(0, 5).trim() === WindowHashID.toString()
  ) {
    return windowHash.slice(5).trim()
  }

  return ''
}

export const setPIDWindowHash = (pid: string) => {
  window.location.hash = `${WindowHashID}${pid}`
}
