import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

import { RewardShopTypeEnum } from '@gamanza-engage/mf-enums'
import { Grid, Typography } from '@mui/material'
import { RewardShopModels, RootState } from '@/state'

import { ProductImagesComponent, ProductComponent } from '@/components'
import { useBreakpoints } from '@/hooks'
import { wysiwygTextProps } from '@/constants'

import { ProductFormStep1Component, ProductFormStep2Component } from './'

type Props = {
  isProductSidebar?: boolean
  product: RewardShopModels.ProductType
  formStep: number
  productForm: RewardShopModels.ProductFormType
  isRewardShop?: boolean
  editLocation: boolean
  actionButtons: React.ReactNode
  handleEditLocation: () => void
  handleOnChange: (field: string, newValue: string | number | boolean) => void
  handleOnChangeMultiple: (
    fields: Partial<RewardShopModels.ProductFormType>,
  ) => void
  getDisableMessage: () => React.ReactNode
}

export function ProductDetailsComponent({
  isProductSidebar,
  product,
  formStep,
  productForm,
  isRewardShop,
  editLocation,
  actionButtons,
  handleEditLocation,
  handleOnChange,
  handleOnChangeMultiple,
  getDisableMessage,
}: Props) {
  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { t } = useTranslation()

  const { isTablet, isMobile } = useBreakpoints()

  const translation =
    product?.translations?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    ) || product?.translations?.[0]

  const isMobileOrTablet = isTablet || isMobile

  const getLongDescription = () => {
    return (
      <>
        <Typography mt={2} fontWeight={700}>
          {t('description')}
        </Typography>
        <Typography component="div" variant="body2" sx={wysiwygTextProps}>
          {parse(translation.longDescription)}
        </Typography>
      </>
    )
  }

  return (
    <ProductComponent isProductSidebar={isProductSidebar}>
      <>
        <Grid
          item
          sm={12}
          md={formStep === 1 || isRewardShop ? 6 : 12}
          width="100%"
        >
          {(formStep === 1 || isRewardShop) && (
            <ProductImagesComponent product={product} />
          )}

          {isRewardShop &&
            !isMobileOrTablet &&
            translation?.longDescription &&
            getLongDescription()}

          {formStep === 2 &&
            !isRewardShop &&
            product?.reward?.type === RewardShopTypeEnum.EXTERNAL && (
              <ProductFormStep2Component
                product={product}
                editLocation={editLocation}
                productForm={productForm}
                actionButtons={actionButtons}
                handleEditLocation={handleEditLocation}
                handleOnChange={handleOnChange}
                handleOnChangeMultiple={handleOnChangeMultiple}
                getDisableMessage={getDisableMessage}
              />
            )}
        </Grid>
        {(formStep === 1 || isRewardShop) && (
          <Grid item xs={12} md={6}>
            <ProductFormStep1Component
              productForm={productForm}
              product={product}
              editLocation={editLocation}
              handleEditLocation={handleEditLocation}
              actionButtons={actionButtons}
              handleOnChange={handleOnChange}
              getDisableMessage={getDisableMessage}
            />

            {isRewardShop &&
              isMobileOrTablet &&
              translation?.longDescription &&
              getLongDescription()}
          </Grid>
        )}
      </>
    </ProductComponent>
  )
}
