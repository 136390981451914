import { makeStyles } from 'tss-react/mui'
import { darken, lighten } from '@mui/material'

type Props = {
  topPosition?: string
}

export const useNavigationArrowsStyles = makeStyles<Props>()((
  theme,
  { topPosition },
) => {
  return {
    baseStyle: {
      position: 'absolute',
      transform: 'translateY(-50%)',
      zIndex: 100,
      width: 30,
      height: 30,
      minWidth: 30,
      padding: 0,
      margin: 0,
    },
    arrowStyle: {
      top: topPosition || '30.5%',
      borderRadius: 21,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      '& svg': {
        fontSize: 16,
      },

      '&.right': {
        '& svg': {
          marginLeft: '3px',
        },
      },

      '&.left': {
        '& svg': {
          marginRight: '3px',
        },
      },

      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.5),
      },
    },
    buttonStyle: {
      top: topPosition || '45%',
      backgroundColor: theme.palette.background.default,
      color:
        theme.palette.mode === 'dark'
          ? lighten(theme.palette.background.paper, 0.2)
          : darken(theme.palette.background.paper, 0.2),
      border: `1px solid ${
        theme.palette.mode === 'dark'
          ? lighten(theme.palette.background.paper, 0.2)
          : darken(theme.palette.background.paper, 0.2)
      }`,

      '& svg': {
        fontSize: 20,
      },

      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }
})
