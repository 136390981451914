import { makeStyles } from 'tss-react/mui'
import { typographyClasses } from '@mui/material'
import { getBorderColorByMode } from '@/constants'

interface Props {
  openSidebar?: boolean
  isActiveBoosters?: boolean
}

export const useHomeStyles = makeStyles<Props>()((
  theme,
  { openSidebar, isActiveBoosters },
) => {
  return {
    homeContent: {
      marginTop: openSidebar ? 40 : 132,
      display: 'flex',
      gap: 1,
      flexDirection: 'column',
      padding: '16px 12px 16px 16px',

      '@-moz-document url-prefix()': {
        padding: openSidebar ? '16px 12px 16px 12px' : '16px 6px 16px 16px',
      },

      [theme.breakpoints.down('sm')]: {
        marginTop: openSidebar ? 40 : 0,
        flexDirection: openSidebar ? 'column' : 'row',
        padding: openSidebar ? 16 : 0,
        width: openSidebar ? 'auto' : '100%',
        gap: openSidebar ? 1 : 30,
        height: openSidebar ? 'calc(100% - 40px)' : 65,
        paddingLeft: isActiveBoosters && !openSidebar && 30,
      },

      [theme.breakpoints.down(480)]: {
        gap: openSidebar ? 1 : 10,
        paddingLeft: isActiveBoosters && !openSidebar && 15,
      },

      '& .titleSection': {
        fontSize: openSidebar ? 16 : 10,
        fontWeight: 700,
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
        textAlign: openSidebar ? 'left' : 'center',
      },

      '& .activeItemsWrapper': {
        [theme.breakpoints.down('sm')]: {
          marginTop: 0,
          position: 'relative',
        },

        '& .activeItemsTitle': {
          display: openSidebar ? 'flex' : 'block',
          flexDirection: 'row',
          gap: 4,
          alignItems: 'center',

          [`& .${typographyClasses.caption}`]: {
            fontSize: 12,
            fontWeight: 600,
          },

          [theme.breakpoints.down('sm')]: {
            display: openSidebar ? 'flex' : 'none',
          },
        },

        '& .activeItemsContent': {
          display: 'flex',
          gap: 10,
          alignItems: 'center',
          justifyContent: openSidebar ? 'left' : 'center',
          backgroundColor: openSidebar
            ? theme.palette.background.default
            : 'transparent',
          borderRadius: 8,
          marginTop: 8,
          marginBottom: 15,
          border: openSidebar
            ? `1px solid ${getBorderColorByMode(theme)}`
            : 'none',
          boxShadow: openSidebar
            ? '0px 4px 20px 0px rgba(0, 0, 0, 0.05)'
            : 'none',
          padding: openSidebar ? 8 : 0,
          minHeight: 60,

          [theme.breakpoints.down('sm')]: {
            marginTop: openSidebar ? 8 : 0,
            marginBottom: openSidebar ? 15 : 0,
            paddingLeft: !openSidebar && 30,
          },

          [theme.breakpoints.down(480)]: {
            paddingLeft: !openSidebar && 15,
          },
        },
      },
    },
  }
})
