import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Typography, useTheme } from '@mui/material'

import { ProgressBarComponent } from '@/components/common'
import { MissionCommonModels, RootState } from '@/state'
import { MissionColors } from '@/constants'

import { VariantsComponentsEnum } from '@/enums'
import { useMissionBundleItemStyles } from './style'

type Props = {
  label: string
  missionBundle: MissionCommonModels.MissionHistoryBundleDetailsType
  variant?: VariantsComponentsEnum
  showEndedMissions?: boolean
}

export const MissionBundleProgressComponent = ({
  missionBundle,
  variant,
  showEndedMissions,
  label,
}: Props) => {
  const { t } = useTranslation()

  const { baseUrl } = useSelector(({ initConfig }: RootState) => initConfig)

  const theme = useTheme()

  const { classes } = useMissionBundleItemStyles({ variant })

  return (
    <Box className={classes.progressBar}>
      <Typography variant="body2">{label}</Typography>
      <ProgressBarComponent
        progressColor={
          showEndedMissions
            ? MissionColors.STATUS.ENDED.content
            : theme.palette.primary.main
        }
        progressValue={missionBundle.state.progress}
        amountLinearBars={missionBundle?.missionIds?.length}
        balanceText={
          missionBundle.state.progress === 100
            ? t('completed').toUpperCase()
            : `${missionBundle.state.completed} / ${missionBundle.state.total}`
        }
        balanceIcon={
          missionBundle.state.progress === 100 && (
            <img
              src={`${baseUrl}assets/icons/common/icon-check-white.svg`}
              alt="Icon Check"
              width={16}
            />
          )
        }
        heightProgress={variant === VariantsComponentsEnum.SMALL ? 16 : 20}
      />
    </Box>
  )
}
