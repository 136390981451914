import { Box, Divider } from '@mui/material'
import { SidebarMenuItemType } from '@/enums'
import {
  SidebarAvatarComponent,
  SidebarPointsComponent,
  SidebarProgressBarComponent,
} from './'

import { useHeaderStyles } from './header.style'
import { useBreakpoints } from '@/hooks'

type Props = {
  Div: HTMLElement
  sidebarClient: string
  sidebarMenuItem: string
  openSidebar: boolean
  handleOpenSidebar?: () => void
}

export const SidebarHeaderComponent = ({
  Div,
  sidebarClient,
  sidebarMenuItem,
  openSidebar,
  handleOpenSidebar,
}: Props) => {
  const headerBackground = Div.getAttribute('data-header-background') || ''

  const { isMobile } = useBreakpoints()

  const { classes } = useHeaderStyles({
    openSidebar,
    headerBackground,
    sidebarMenuItem,
  })

  return (
    <Box className={classes.sidebarHeader} onClick={handleOpenSidebar}>
      <Box display="flex" gap={1.5}>
        <SidebarAvatarComponent
          Div={Div}
          openSidebar={openSidebar}
          handleOpenSidebar={handleOpenSidebar}
        />
        {isMobile && !openSidebar && (
          <Divider orientation="vertical" flexItem />
        )}
        {openSidebar && (
          <SidebarProgressBarComponent
            Div={Div}
            sidebarClient={sidebarClient}
            openSidebar={openSidebar}
          />
        )}
      </Box>
      {(sidebarMenuItem === SidebarMenuItemType.HOME || !openSidebar) && (
        <SidebarPointsComponent
          sidebarClient={sidebarClient}
          openSidebar={openSidebar}
        />
      )}
    </Box>
  )
}
