import { useState } from 'react'
import { SidebarMenuItemType } from '@/enums'

export const useSidebarActions = () => {
  const [openSidebar, setOpenSidebar] = useState(false)

  const [fullContent, setFullContent] = useState(false)

  const [sidebarMenuItem, setSidebarMenuItem] = useState(
    SidebarMenuItemType.HOME,
  )

  const handleOpenSidebar = () => {
    setOpenSidebar(!openSidebar)
  }

  const handleChangeMenuItem = (sidebarMenuItem: SidebarMenuItemType) => {
    setSidebarMenuItem(sidebarMenuItem)
    setFullContent(false)
  }

  const handleSetFullContent = (full: boolean) => {
    setFullContent(full)
  }

  return {
    openSidebar,
    sidebarMenuItem,
    fullContent,
    handleOpenSidebar,
    handleChangeMenuItem,
    handleSetFullContent,
  }
}
