import { TimeFrameEnum, TimeUnitsEnum } from '@gamanza-engage/mf-enums'

export const time = {
  day_one: 'Day',
  day_other: 'Days',
  duration: 'Duration',
  durationType: {
    Y: 'Years',
    W: 'Weeks',
    Mth: 'Months',
    D: 'Days',
    H: 'Hours',
    Min: 'Minutes',
    S: 'Seconds',
  },
  expires: 'Expires',
  hour_one: 'Hour',
  hour_other: 'Hours',
  minute_one: 'Minute',
  minute_other: 'Minutes',
  month_one: 'Month',
  month_other: 'Months',
  nextTimeText: {
    [TimeFrameEnum.DAILY]: 'tomorrow',
    [TimeFrameEnum.WEEKLY]: 'next week',
    [TimeFrameEnum.MONTHLY]: 'next month',
  },
  timeframeType: {
    [TimeFrameEnum.DAILY]: 'Daily',
    [TimeFrameEnum.WEEKLY]: 'Weekly',
    [TimeFrameEnum.LIFETIME]: 'Lifetime',
    [TimeFrameEnum.RANGE]: 'Range',
    [TimeFrameEnum.MONTHLY]: 'Monthly',
  },
  timeframeTypeMissions: {
    [TimeFrameEnum.DAILY]: 'Daily Missions',
    [TimeFrameEnum.WEEKLY]: 'Weekly Missions',
    [TimeFrameEnum.LIFETIME]: 'Lifetime Missions',
    [TimeFrameEnum.RANGE]: 'Range Missions',
    [TimeFrameEnum.MONTHLY]: 'Monthly Missions',
  },
  timeText: {
    [TimeFrameEnum.DAILY]: 'Today',
    [TimeFrameEnum.WEEKLY]: 'This Week',
    [TimeFrameEnum.MONTHLY]: 'This Month',
  },
  timeUnitsText: {
    [TimeUnitsEnum.DAYS]: 'Days',
    [TimeUnitsEnum.WEEKS]: 'Weeks',
    [TimeUnitsEnum.MONTHS]: 'Months',
    [TimeUnitsEnum.YEARS]: 'Years',
    [TimeUnitsEnum.HOURS]: 'Hours',
  },
  week_one: 'Week',
  week_other: 'Weeks',
  year_one: 'Year',
  year_other: 'Years',
}
