import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RewardStatusEnum, TimeFrameEnum } from '@gamanza-engage/mf-enums'

import { Box, Button, Chip, Typography } from '@mui/material'
import { AccessTime, ArrowForwardIos, Today } from '@mui/icons-material'
import {
  ClaimChestItemComponent,
  MissionBundleProgressComponent,
} from '@/components/common'

import { MissionCommonModels, RootState } from '@/state'
import { ChestTypeEnum, VariantsComponentsEnum } from '@/enums'
import { useBreakpoints, useRemainingTime } from '@/hooks'

import { formatDateInUTC } from '@/utils'
import { ImageDefault } from '@/constants'
import { useMissionBundleItemStyles } from './style'

type Props = {
  missionBundle: MissionCommonModels.MissionHistoryBundleDetailsType
  variant?: VariantsComponentsEnum
  showProgressBar?: boolean
  showEndedMissions?: boolean
  noBorderBottom?: boolean
  borderRadius?: number
  customHeight?: number
  isMissionsPage?: boolean
  openMissions?: () => void
}

export const MissionBundleItemComponent = ({
  missionBundle,
  variant = VariantsComponentsEnum.LARGE,
  showProgressBar = false,
  showEndedMissions = false,
  noBorderBottom = false,
  borderRadius,
  customHeight,
  isMissionsPage,
  openMissions,
}: Props) => {
  const { t } = useTranslation()

  const { isMobile } = useBreakpoints()

  const { classes, cx } = useMissionBundleItemStyles({
    variant,
    borderRadius,
    customHeight,
  })

  const { playerLocale } = useSelector(
    ({ initConfig }: RootState) => initConfig,
  )

  const { timeRemaining } = useRemainingTime({
    endDate: missionBundle.endDate,
  })

  const listMissionsUnclaimed = missionBundle.missionIds.filter(
    (mission) =>
      mission?.rewardClaimStatus?.status === RewardStatusEnum.UNCLAIMED &&
      mission?.rewardCategories?.filter(
        (category) => category.status === RewardStatusEnum.CLAIMED,
      ).length > 0,
  )

  const showClaimChest = listMissionsUnclaimed.length > 0

  const getStarDateByTimeframe = () => {
    const timeFrameType = missionBundle.settings.timeFrame.timeFrameType
    if (
      (timeFrameType === TimeFrameEnum.DAILY ||
        timeFrameType === TimeFrameEnum.MONTHLY) &&
      missionBundle.settings.timeFrame.date
    ) {
      return formatDateInUTC(
        missionBundle.settings.timeFrame.date,
        timeFrameType === TimeFrameEnum.DAILY ? 'dd MMM' : 'MMMM',
      )
    }

    if (
      (timeFrameType === TimeFrameEnum.WEEKLY ||
        timeFrameType === TimeFrameEnum.RANGE) &&
      missionBundle.settings.timeFrame.startDate &&
      missionBundle.settings.timeFrame.endDate
    ) {
      const startDate = formatDateInUTC(
        missionBundle.settings.timeFrame.startDate,
        'dd MMM',
      )

      const endDate = formatDateInUTC(
        missionBundle.settings.timeFrame.endDate,
        'dd MMM',
      )

      return `${startDate} - ${endDate}`
    }
  }

  const missionBundleName =
    missionBundle.externalDetails?.find(
      (item) => item.language.toUpperCase() === playerLocale.toUpperCase(),
    )?.name || missionBundle.internalDetails.name

  const missionBundleThumbnail = missionBundle.smallImage || ImageDefault

  const missionBundleLargeImage = missionBundle.largeImage || ImageDefault

  const showTimeRemaining =
    !showEndedMissions ||
    (showEndedMissions &&
      missionBundle.settings.timeFrame.timeFrameType !== TimeFrameEnum.LIFETIME)

  return (
    <Box
      className={`${classes.missionBundleItemWrapper} ${cx({
        noBorderBottom: noBorderBottom,
      })}`}
      mt={isMissionsPage ? 0 : -2}
    >
      <img
        className="bundleImage"
        src={
          isMissionsPage && !isMobile
            ? missionBundleLargeImage
            : missionBundleThumbnail
        }
        alt="Mission Bundle Preview"
      />
      <Box className="bundleChip bundleTimeframeType">
        <Chip
          label={t(
            `timeframeType.${missionBundle.settings.timeFrame.timeFrameType}`,
          )}
          variant="filled"
          size="small"
        />
      </Box>
      {showTimeRemaining && (
        <Box className="bundleChip bundleTimeRemaining">
          <Chip
            icon={showEndedMissions ? <Today /> : <AccessTime />}
            label={showEndedMissions ? getStarDateByTimeframe() : timeRemaining}
            variant="filled"
            size="small"
          />
        </Box>
      )}
      {(showClaimChest || showProgressBar) && (
        <Box className="bundleItemFooter">
          {showClaimChest && (
            <Box className="bundleClaimChest">
              <ClaimChestItemComponent
                bundleId={listMissionsUnclaimed[0].missionBundleId}
                missionId={listMissionsUnclaimed[0]._id}
                rewards={
                  listMissionsUnclaimed[0].rewardCategories?.filter(
                    (category) => category.status === RewardStatusEnum.CLAIMED,
                  )[0]?.rewards || []
                }
                amountChest={listMissionsUnclaimed.length}
                variant={variant}
                chestType={ChestTypeEnum.MISSION}
              />
            </Box>
          )}
          {showProgressBar && (
            <Box className="bundleProgressAndMissions">
              <MissionBundleProgressComponent
                missionBundle={missionBundle}
                variant={variant}
                showEndedMissions={showEndedMissions}
                label={missionBundleName}
              />
              <Button
                className="seeMissions"
                variant="outlined"
                onClick={() => {
                  openMissions && openMissions()
                }}
              >
                <Typography variant="caption">{t('seeMore')}</Typography>
                <ArrowForwardIos />
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
