import { makeStyles } from 'tss-react/mui'
import { SidebarMenuItemType } from '@/enums'
import { SidebarColors } from '@/constants'

type Props = {
  openSidebar: boolean
  headerBackground?: string
  sidebarMenuItem?: string
}

export const useHeaderStyles = makeStyles<Props>()((
  theme,
  { openSidebar, headerBackground, sidebarMenuItem },
) => {
  const background =
    headerBackground || SidebarColors(theme).headerBackgroundColor

  const getPaddingBottom = (isMobile: boolean) => {
    if (!isMobile && !openSidebar) return 45

    if (openSidebar) {
      return sidebarMenuItem === SidebarMenuItemType.HOME ? 88 : 32
    }

    if (isMobile && !openSidebar) {
      return 20
    }
  }

  return {
    sidebarHeader: {
      paddingLeft: openSidebar ? 16 : 20,
      paddingRight: openSidebar ? 16 : 20,
      paddingTop: 16,
      paddingBottom: getPaddingBottom(false),
      background,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      cursor: 'pointer',

      [theme.breakpoints.down('sm')]: {
        background: openSidebar ? background : theme.palette.background.default,
        borderTopLeftRadius: openSidebar ? 0 : 16,
        borderTopRightRadius: openSidebar ? 0 : 16,
        paddingTop: 10,
        paddingBottom: getPaddingBottom(true),
      },
    },
  }
})
