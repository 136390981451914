import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { ActiveBoosterType } from '@/state/my-booster/models'

import { PopperComponent } from '@/components/common'
import { ActiveBoosterItemComponent } from './active-booster-item.component'
import { useAnimations } from './animations'

type Props = {
  Div: HTMLElement
  popperBoosters: ActiveBoosterType[]
  open: boolean
  anchorEl: null | HTMLElement
  hidePopperItems: () => void
}

export function PopperBoosterComponent({
  Div,
  popperBoosters,
  open,
  anchorEl,
  hidePopperItems,
}: Props) {
  const { classes: classesAnimation } = useAnimations()

  const [secondLoad, setSecondLoad] = useState(false)

  useEffect(() => {
    if (open && !secondLoad) {
      setSecondLoad(true)
    }
  }, [open]) // eslint-disable-line

  const handleClickAway = (event: MouseEvent) => {
    if (!anchorEl || anchorEl.contains(event.target as Node)) {
      return
    }
    hidePopperItems()
  }

  return (
    <PopperComponent
      anchorEl={anchorEl}
      open={open}
      handleClickAway={handleClickAway}
      keepMounted={secondLoad}
    >
      <Box className={classesAnimation.animations}>
        <Box
          width={268}
          padding="20px 20px 10px"
          maxHeight={307}
          overflow="auto"
        >
          {popperBoosters.map((booster) => {
            return (
              <ActiveBoosterItemComponent
                Div={Div}
                booster={booster}
                popoverItem
                key={`active-booster-${booster._id}`}
              />
            )
          })}
        </Box>
      </Box>
    </PopperComponent>
  )
}
