import React from 'react'
import { Box, IconButton, SxProps, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

type Props = {
  title?: string
  titleSxProps?: SxProps
  interaction?: React.ReactNode
  customGoBackButton?: React.ReactNode
  goBack?: () => void
}

export function HeaderNavigationComponent({
  interaction,
  title,
  titleSxProps,
  customGoBackButton,
  goBack,
}: Props) {
  const getDefaultGoBack = () => {
    if (!!customGoBackButton) {
      return customGoBackButton
    }

    return (
      !!goBack && (
        <IconButton
          sx={{ fontSize: 16, color: 'inherit' }}
          size="small"
          onClick={goBack}
        >
          <ArrowBack fontSize="inherit" />
        </IconButton>
      )
    )
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" gap={0.5}>
        {getDefaultGoBack()}
        {!!title && (
          <Typography fontSize={16} fontWeight={700} sx={titleSxProps}>
            {title}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {!!interaction && interaction}
      </Box>
    </Box>
  )
}
